// Chakra imports
import { Text, Flex, useColorModeValue } from "@chakra-ui/react";
// Assets



// Custom components #949cbf

import { Icon } from "@chakra-ui/react";
import {
  MdHome, MdLineAxis, MdDashboard, MdPeople, MdPerson, MdDownload,
} from "react-icons/md";


import logoWhite from "assets/img/layout/aerilogo1.png";






import Card from "components/card/Card.js";
import React from "react";
import Project from "views/admin/dashboard/components/dashboardlist";

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Flex   direction={{ base: "column", md: "column", xl: "row" }} borderRadius='5px' mb={{ base: "10px", "2xl": "10px" }}
mr={{ base:"10px", md: "10px", xl: "10px" }} mt={{ base:"50px", md: "10px", xl: "10px" }}>

<Project
    link='./dashboardA'
    chart='5'
    title='Top 10 Energie aanbieders'
    tag1=''
    tag2=''
    cardcolor='blue.900'
    textcolor='gray.100'
/>


<Project  
    link='./dashboardB'
  chart='4'
  title='Top 10 verzekeraars'
  tag1='Export'
  tag2='Import'
  cardcolor='green.900'
  textcolor='white'

/>




      
    </Flex>
  );
}
