import Project from "views/admin/energiedash/components/dashboardlist";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Flex, Button } from "@chakra-ui/react";

export default function DashboardAll({ selectedUser, setSelectedUser }) {
  const [users, setUsers] = useState([]);

  const handleUserChange = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);
  };

  useEffect(() => {
    axios.get('http://localhost:8080/getRegion')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleEdit = (projectId) => {
    console.log("Editing project with ID:", projectId);
  };

  return (
    <Flex direction={{ base: "column", md: "column", xl: "row" }} borderRadius='5px' mb={{ base: "10px", "2xl": "20px" }}>
      <Project
        link={selectedUser ? (users.find(user => user._id === selectedUser)?.link ||"data-m") : ("")}
        chart={selectedUser ? (users.find(user => user._id === selectedUser)?.chart ||"data-m") : ("")}
        title={selectedUser ? (users.find(user => user._id === selectedUser)?.dashboardtitle ||"data-m") : ("")}
        tag1={selectedUser ? (users.find(user => user._id === selectedUser)?.tag1 ||"data-m") : ("")}
        tag2={selectedUser ? (users.find(user => user._id === selectedUser)?.tag2 ||"data-m") : ("")}
        tag3={selectedUser ? (users.find(user => user._id === selectedUser)?.tag3 ||"data-m") : ("")}
        textinfo={selectedUser ? (users.find(user => user._id === selectedUser)?.textinfo ||"data-m") : ("")}
      >
        <Button onClick={() => handleEdit("A")} variant="outline" size="sm" colorScheme="blue">Edit</Button>
      </Project>
      <Project
        link={selectedUser ? (users.find(user => user._id === selectedUser)?.link ||"data-m") : ("")}
        chart={selectedUser ? (users.find(user => user._id === selectedUser)?.chartB ||"data-m") : ("")}
        title={selectedUser ? (users.find(user => user._id === selectedUser)?.dashboardtitleB ||"data-m") : ("")}
        tag1={selectedUser ? (users.find(user => user._id === selectedUser)?.tag1B ||"data-m") : ("")}
        tag2={selectedUser ? (users.find(user => user._id === selectedUser)?.tag2B ||"data-m") : ("")}
        tag3={selectedUser ? (users.find(user => user._id === selectedUser)?.tag3B ||"data-m") : ("")}
        textinfo={selectedUser ? (users.find(user => user._id === selectedUser)?.textinfoB ||"data-m") : ("")}
      >
        <Button onClick={() => handleEdit("B")} variant="outline" size="sm" colorScheme="blue">Edit</Button>
      </Project>
    </Flex>
  );
}
