
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'



import { Box, Text, Flex, Link, useColorModeValue, SimpleGrid, BreadcrumbLink, Breadcrumb,
  BreadcrumbItem,  } from "@chakra-ui/react";
import React from "react";
import DashboardAll from "views/admin/energiedash/components/dashboardsall";
import MainDash from "views/admin/energiedash/components/maindashallEU";

import Card from "components/card/Card";
import Logos from "views/admin/profile/components/logos";
import Logos2 from "views/admin/profile/components/logos2";

import { ChevronRightIcon, } from '@chakra-ui/icons'



export default function Settings() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>

      <Flex mb="10px">  
          <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>


            <BreadcrumbItem>
              <ChakraLink   as={ReactRouterLink} color='gray.500' to='./dashboards'>Dashboards</ChakraLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
            <ChakraLink   as={ReactRouterLink} color='gray.800' to='./dashboardA'>Regional Dashboard - Europe</ChakraLink>
            </BreadcrumbItem>
          </Breadcrumb>
      </Flex>



      <SimpleGrid h='100%' columns={{ base: 1, md: 1, xl: 2 }} gap='10px' mb='0px'>
   
      </SimpleGrid>

      
      
      
      
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='10px' mb='0px'>
    
        <MainDash />
        <DashboardAll />

      </SimpleGrid>









      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='10px' mb='10px'>


      
      
      <Card
      direction='column'
      w='100%'
      h='120px'
      px='0px'
      borderRadius='5px'
      overflowX={{ sm: "hidden", lg: "hidden" }}>


<Logos2/>

      </Card>


      </SimpleGrid>







    </Box>
  );
}
