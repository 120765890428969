// Chakra imports
import { useState } from "react";
import ReactApexChart from "react-apexcharts"; // Corrected import statement
import { Flex } from "@chakra-ui/react";



// Custom components

// Assets
export default function GeneralInformation(props) {
  const { 
    DashPar0,
    DashPar1,
    DashPar2,
    DashPar3,
    DashPar4,
    title,
    link, 
    ink2, 
    chart, 
    textinfo, 
    CirPro1, 
    CirProTag1, 
    CirProTag2, 
    CirProTag3, 
    CirProTag4, 
    tag1, tag2, 
    tag3, ...rest } = props;
  // Chakra Color Mode


  // Chart options and data
  const [chartData, ] = useState({


    options: {
      chart: {
        width: 500,
        type: 'polarArea'
      },
      labels: [' 0 to 15', ' 15 to 35', ' 35 to 65',' 65+',],
      fill: {
        colors: ['#fff', '#66DA26', '#546E7A', ],
        opacity: 0.8,
        type: 'solid',
        gradient: {
            shade: 'dark',
            type: "horizontal",
            shadeIntensity: 1.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: []
        },
        image: {
            src: [],
            width: undefined,
            height: undefined
        },
        pattern: {
            style: 'verticalLines',
            width: 6,
            height: 6,
            strokeWidth: 2,
        },
      },
      stroke: {
        width: 2,
        colors: 'white'
      },
      yaxis: {
        show: false
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'right',
        horizontalAlign: 'center', 
        floating: false,
        fontSize: '10px',
        fontColor: '#fff',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 200,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
            colors:  '#fff',
            useSeriesColors: false
        },
        markers: {
            width: 10,
            height: 10,
            strokeWidth: 2,
            strokeColor: '#fff',
            fillColors: ['#fff', '#66DA26', '#546E7A', ],
            radius: 0,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
        },
        itemMargin: {
            horizontal: 5,
            vertical: 0
        },
        onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: true
        },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
          return val
      },
      textAnchor: 'start',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: undefined
      },
      background: {
        enabled: true,
        foreColor: '#000',
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: '#fff',
        opacity: 0.4,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
      }
    },



      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0
          },
          spokes: {
            strokeWidth: 0
          },
        }
      },
      theme: {
        monochrome: {
          enabled: false,
          color: '#',
          shadeTo: 'light',
          shadeIntensity: 1.0
        }
      }
    },
    series: [15, 12, 15, 12],
  });

  return (
<Flex backgroundColor='gray.800' borderRadius='5px' direction="column" flex="1" justifyContent="center" >

     


{/* ReactApexChart component */}
<ReactApexChart
  options={chartData.options}
  series={chartData.series}
  type="polarArea"
  height="100%"
  width="100%"
/>



</Flex>
  );
}
