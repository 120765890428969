// Chakra imports
import { 
  Spacer,
  Divider,
  InputGroup,
  InputLeftAddon,
  Input,
  Image, 
  Center,
   Button,
   Stack,
   Box, 
   Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
   Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useEffect } from "react";
import General from "views/admin/profile/components/General";

import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'


import { EmailIcon , ArrowForwardIcon, WarningIcon } from '@chakra-ui/icons'






export default function Banner(props) {


  useEffect(() => {
    // Load the script when the component mounts
    const script = document.createElement("script");
    script.src = "https://daisycon.tools/prefill-energy-nl/app.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const link1 = "./energievergelijken";
  const link2 = "./energievergelijken";
  const link3 = "./energievergelijken";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (  
    <Flex 
    mb='10px'
    mt='10px'
    pe='0px'
    bg='teal'
    width='100%'
    maxWidth='800px'
    alignContent='center'
    alignItems='center'

    h="100%"
    borderRadius='15px'
    direction='column'
    >   

{/* Main Block Inside Card - COLOR*/}


<Flex
      bg='#090a1c'
      mb='0px'
      borderRadius='15px'
      mt='20px'
      px='10px'
      alignItems='center'
      alignContent='center'
      justifyContent='center'
      width='99%'
      h='100%'
> 
    <Flex
          mb='0px'
          mt='10px'
          maxWidth='500px'
    > 
    <div class="dc-tool dc-prefill-energy-tool" data-config='{"mediaId":{"daisycon":399243,"xpartners":null},"subId":{"daisycon":"","xpartners":null},"locale":"nl-NL","colorPrimary":"#090a1c" ,"colorSecondary":"#008080","buttonText":"Vergelijk","filter":{"amountPeriod":{"value":"year"},"providerId":{"value":[10]},"sustainabilityElectricityLevels":{"value":["3"]}},"link":"http://www.prijsprofijt.nl/admin/energievergelijker"}'></div>
    </Flex>
</Flex>



      </Flex> 
  );
}
