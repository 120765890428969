import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'
import { MdChecklistRtl,  MdCallSplit, MdOutlineCompareArrows } from "react-icons/md";
import LogoIMG from "assets/img/layout/aerilogo3.png";

import { 
  MdSettings,
  MdMoneyOff,} from "react-icons/md";
import {  } from '@mdi/js';
import { 
  Flex,
  Icon, 
  Box, 
  Text, 
  Input, 
  Button,
  Stack,
  InputRightElement,
  Image,
  Center,
Square,



} from "@chakra-ui/react";

import { EmailIcon , CheckIcon,  } from '@chakra-ui/icons'


// const response = await axios.post("http://localhost:8080/saveAnswers", answers);
// const response = await axios.post("https://aeri-api.onrender.com/saveAnswers", answers);


// Fetch data from the server endpoint LINK: https://aeri-api.onrender.com/getCountry

const submitAnswers = async (answers) => {
  try {
    const response = await axios.post("https://aeri-api.onrender.com/saveAnswers", answers);
    console.log("Answers saved:", response.data);
    // Handle success, e.g., show a success message to the user
  } catch (error) {
    console.error("Error saving answers:", error);
    // Handle error, e.g., show an error message to the user
  }
};





export default function Banner(props) {

  const [users, setUsers] = useState([]);


  const handleSubmit = () => {
    // Call submitAnswers function when the user clicks the submit button
    submitAnswers(answers);
    // For demonstration purposes, set answersSubmitted to true
    setAnswersSubmitted(true);




  };

  useEffect(() => {
    // Fetch data from the http://localhost/8080/getCountry'
  
  // Fetch data from the server endpoint LINK: https://aeri-api.onrender.com/getCountry'
  axios.get('http://localhost/8080/getCountry')
  .then(response => {
    // Update the state with the fetched data
    setUsers(response.data);
  })
  .catch(error => {
    console.error('Error fetching users:', error);
  });
  }, []); // Empty dependency array to run only once when the component mounts
  
  const cardcolor = '#090a1c';
  const textcolor = 'green.400';
  const textcolor2 = 'gray.200';
  const iconcolor = 'gray.300';
  const [answers, setAnswers] = useState({}); // State to store answers
  const [answersSubmitted, setAnswersSubmitted] = useState(false); // State to track if answers are submitted

  // Function to handle input change
  const handleInputChange = (question, value) => {
    setAnswers({ ...answers, [question]: value });
  };

  const boxesData = [
    {
      icon: MdMoneyOff,
      title: "Bespaar",
      description: "geld ",
    },
    {
      icon: MdOutlineCompareArrows,
      title: "Aanbieders",
      description: "alle",
    },
    {
      icon: MdChecklistRtl,
      title: "Prijs analyse",
      description: "check",
    },
    {
      icon: MdCallSplit,
      title: "Overstappen",
      description: "binnen minuten ",
    },
    // Add more objects for additional variations
  ];





  return (
    <Flex
    bg=""
    mt="10px"
    mb="20px"
    pe="0px"


    borderRadius="15px"
    direction={{ base: "column", md: "column", sm: "column" }}

    alignItems="center"
    justifyContent="center"
    overflow="noscroll" // Set overflow property to auto
  >

<Flex
 direction={{ base: "column", md: "column" }}
> 
    <Text  textAlign='center' mt="10px"
    fontSize={{base: "3xl", md: "md", sm: "md" }}
     fontWeight="900">
      Profiteer nu van de <i><u>beste</u></i> deals
    </Text>
</Flex>

<Flex
gap='5px'
> 
    {boxesData.map((data, index) => (
      <Box
        key={index}
        display="flex"
   
        alignItems="center"
        justifyContent="center"
        bg={cardcolor}
        w={{ base: "100%",
        sm: "85px",
        md: "calc(25% - 0px)"
        
        
        
         }}
        maxW="300px"
        minW={{ base: "150px 0", md: "175px", sm: "0px", lg: "250px" }}
        maxH="250px"
        minH={{ base: "150px 0", md: "200px", sm: "100px", lg: "250px" }}
        p={4}
        color="white"
        m={{ base: "10px 0", md: "10px" }}
        borderRadius="15px"
      >
        <Center flexDirection="column">
          <Icon as={data.icon} 
          color={iconcolor}
          boxSize={{ base: "64px", sm: "20px", md: "50px" }}
          mb="2" />
          <Text  
          textAlign='center'
          color={textcolor}
          as="b"
          
          
          
          fontSize={{ base: "md", sm: "xs", md: "lg" }}
          
          
          fontWeight="900">
            {data.title}
          </Text>
          <Text as="p" 
          color={textcolor2}
          fontSize={{ base: "md", sm: "0px", md: "md" }}
          >
            {data.description}
          </Text>
        </Center>
      </Box>
    ))}
    </Flex>
  </Flex>
  );
}
