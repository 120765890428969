// Chakra imports
import { 
  Spacer,
  Divider,
  Image, 
  Center,
   Button,
   Stack,
   Box, 
   Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
   Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import General from "views/admin/profile/components/General";

import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'


import { EmailIcon , ArrowForwardIcon, WarningIcon } from '@chakra-ui/icons'






export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const link1 = "./energievergelijken";
  const link2 = "./verzekeringvergelijken";
  const link3 = "./hypotheekvergelijken";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (  

      <Flex 
      mb='0px'
      mt='10px'
      pe='0px'
      bg='#090a1c'
      width='100%'
      h="350px"
      maxWidth="100%"
      justifyContent='flex-end'
      borderRadius='15px'
      direction='column'>   

{/* Main Block Inside Card - COLOR*/}
<Flex      
      bg='white'

      maxWidth="100%"


      mt='0px'  
      ml='10px' 
      mr='10px'   
  
      mb='-5px'        
      borderRadius='15px'
      borderWidth='0px'
      borderColor='gray.200'

      direction='column'> 
 

{/* Lijst en Icons Producten */}















<Flex 
  direction='column'
  mt='20px'
  ml='0px'
  gap='5px'
>


{/* Title Components */}
<Text 
width='100%'
textAlign='center'
fontWeight='900'
color='gray.800'
fontSize='2xl'
mb='20px'
>
Vergelijk en <i><b><u>Profiteer</u></b></i>
</Text>


{/* First Box */}
  <Box  
    w='100%'
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent=""
    borderRadius='0' 
    bg='gray.100' 
    color='green.600' 
    px={5} 
  >
<Image
      w='40px'
      h='40px'
      mt='5px'
      mr='10px'
      opacity='100%'
      objectFit='cover'
      src='https://cdn-icons-png.freepik.com/512/286/286320.png'
      style={{
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
    />


    <div 
    style={{ flexGrow: 1 }}>
        <ChakraLink
     as={ReactRouterLink} to={link1}
    >   
    
      <Button 
             mt='5px'

        rightIcon={<ArrowForwardIcon />} 
        justifyContent=""
        borderRadius='15px' 
        w='100%'
        maxW='200px'
        colorScheme='teal' 
        size='lg'
      >


        <Text 
        fontSize='2xl'
        as='b'
        > Energie </Text>
      </Button>
      </ChakraLink>    
    </div>
    
  </Box>


<Center> 
  <Divider 
    width='65%'
    borderColor='gray.700' // Set the color of the Divider to gray
    borderRadius='3px'
    borderWidth='0px'
  />
 </Center>

{/* Second Box */}
 <Box  
    w='100%'
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent=""
    borderRadius='0' 
    bg='gray.100' 
    color='gray.800' 
    px={5} 
  >
    <Image
      w='40px'
      h='40px'
      mt='5px'
      mr='10px'
      opacity='100%'
      objectFit='cover'
      src='https://icons.veryicon.com/png/o/business/one-meter-information/emi-insurance.png
'
      style={{
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
    />

    <div 
    style={{ flexGrow: 1 }}>
        <ChakraLink
     as={ReactRouterLink} to={link2}
    >   
      <Button 
             mt='5px'

        rightIcon={<ArrowForwardIcon />} 
        justifyContent=""
        borderRadius='15px' 
        w='100%'
        maxW='250px'
        colorScheme='green' 
        size='lg'
      >
        <Text 
        fontSize='2xl'
        as='b'
        > Verzekeringen</Text>
      </Button>
      </ChakraLink>    
    </div>
    
  </Box>


  <Center> 
  <Divider 
    width='45%'
    borderColor='gray.700' // Set the color of the Divider to gray
    borderRadius='3px'
    borderWidth='0px'
  />
 </Center>

{/* Third Box */}

 <Box  
    w='100%'
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent=""
    borderRadius='0' 
    bg='gray.100' 
    color='gray' 
    px={5} 
  >
    <Image
      w='40px'
      h='40px'
      mt='5px'
      mr='10px'
      opacity='100%'
      objectFit='cover'
      src='https://cdn-icons-png.flaticon.com/512/888/888419.png'
      style={{
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
    />


    <div 
    style={{ flexGrow: 1 }}>
        <ChakraLink
     as={ReactRouterLink} to={link3}
    >   
      <Button 
             mt='5px'

        rightIcon={<ArrowForwardIcon />} 
        justifyContent=""
        borderRadius='15px' 
        w='100%'
        maxW='200px'
        colorScheme='gray'  
        size='lg'
      >
                <Text 
        fontSize='2xl'
        as='b'
        > Hypotheek</Text>
      </Button>
      </ChakraLink>    
    </div>
    
  </Box>


  <Center> 
  <Divider 
    width='65%'
    borderColor='gray.700' // Set the color of the Divider to gray
    borderRadius='3px'
    borderWidth='0px'
  />
 </Center>









</Flex>




{/* Text + Buttons Meer Producten */}
<Flex 
  mt='20px'
  px='10px'
  width='100%'
  alignItems='flex-start'
  direction='column'
> 
<Text 
  width='100%'
textAlign='center'
fontWeight='500'
color='gray.600'
fontSize='18px'
>
</Text>


{/* Buttons 2 Meer producten LINK */}

<Flex
        mt='10px'
        mb='20px'
        gap='0px'
        alignSelf='right'   
 >  





</Flex>






</Flex>




</Flex>
      </Flex> 
  );
}
