
// Chakra imports
import {
  Box,
  Grid,
  Text,
  Flex,
  SimpleGrid,
  Image,
  Spacer,
  Button,

} from "@chakra-ui/react";

// Custom components
import Projects from "views/admin/profile/components/Projects";
import Logos from "views/admin/profile/components/logos";
import Logos2 from "views/admin/profile/components/logos2";
import Homepageblock from "views/admin/profile/components/homepageblock";
import Homepageblock2 from "views/admin/profile/components/homepageblock2";
import Homepageblock3 from "views/admin/profile/components/homepageblock3";
import Homepagedeals from "views/admin/profile/components/homepagedeals";
import Products from "views/admin/profile/components/productencomp";

import Info from "views/admin/profile/components/info";


import Card from "components/card/Card";
import DashboardAll from "views/admin/dashboard/components/dashboardsall";



// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React from "react";

export default function Overview() {
  return (
<Box pt={{ base: "20px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}

      
      {/* Dashboards */}
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='0px'>
      <Products/>

  
      <Homepagedeals/>


    </SimpleGrid>
    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='0px'>


      <Homepageblock3/>



    </SimpleGrid>

      {/* Dashboards */}
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='30px' mt='30px'>
      
        
      <Homepageblock/>





    </SimpleGrid>





 






      {/* Info */}
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='10px'>
      <Card
      borderRadius="5px"
      backgroundColor="white"
      height="100px"
      >







 







      </Card>
    </SimpleGrid>
















</Box>
  );
}
