import React from 'react';
import { Flex, Box, Icon, Text, Button, Accordion, List, ListItem, ListIcon, AccordionItem, AccordionPanel, AccordionIcon, AccordionButton, useColorModeValue } from "@chakra-ui/react";
import { MdLineAxis, MdCheckCircle, MdSettings, MdDashboard, MdCarRental, MdAnalytics, MdInfo, MdAreaChart, MdTableChart } from "react-icons/md";
import Card from "components/card/Card.js";
import { IoMdInformation } from 'react-icons/io';






const ChartInfobar = ({ handleChartChange }) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  return (
    <Card borderRadius='0px' w='100%' h='150%' >

<Text fontSize={14}> </Text>  







    
    </Card>
  );
}

export default ChartInfobar;
