import { Icon } from "@chakra-ui/react";
import { MdLineAxis, MdDashboard, MdTableRows, MdTabUnselected, MdTableChart, MdInfo } from "react-icons/md";
import { Button, Tabs,
  Stack,
  Divider,
AbsoluteCenter,
Badge,
  TabList,
  Tab,
  TabPanels,
  TabPanel, } from "@chakra-ui/react";
import Chart from "views/admin/analyse/components/chart";
import Chart2 from "views/admin/analyse/components/chart2";
import Chart3 from "views/admin/analyse/components/chart3";
import ChartA1 from "views/admin/analyse/components/chartA1";
import Chart4 from "views/admin/analyse/components/chart4";



import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Project(props) {
  const { title, link, link2, chart, textinfo, texttable, tag1, tag2, tag3, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const cardcolor = "gray.100";
  const brandColor = useColorModeValue("brand.500", "white");

  const bg = useColorModeValue("white", "navy.700");

  let chartComponent;
  switch (chart) {
    case "1":
      chartComponent = <Chart />;
      break;
    case "2":
      chartComponent = <Chart2 />;
      break;
    // Add more cases for additional chart types if needed
    case "3":
      chartComponent = <Chart3 />;
      break;
    case "A1":
      chartComponent = <ChartA1 />;
      break;
    case "4":
      chartComponent = <Chart4 />;
      break;


        


    default:
      chartComponent = null;
  }

  return (
    <Card
      borderRadius="5px"
      backgroundColor={cardcolor}
      height="100%"
      mb={{ base: "10px", lg: "10px" }}
      mr={{ base: "10px", lg: "10px" }}
      align="center"
    >
      <Flex  direction="column">
        <Box mt={{ base: "0px", md: "0px", sm: "0px" }}>
          
          
            <Text
              color='blackAlpha.900'
              fontWeight="800"
              fontSize="20px"
              textAlign="left"
              mb="0px"
            >
              {title}
            </Text>

    
      


          <Flex
    width="100%" // Make sure the buttons span the full width of the card
    height="260px" // Make sure the buttons span the full width of the card

  >
          {chartComponent}
     </Flex>


     <Flex
            mt="10px"
            gap="15px"
    justify="space-evenly"

    align="center"
    justifyContent="right"


          >
     <Stack direction='row'>
  <Badge fontSize='10'>{tag1}</Badge>
  <Badge fontSize='10' colorScheme='blue'>{tag2}</Badge>
  <Badge fontSize='10'>{tag3}</Badge>
</Stack>
</Flex>


<Divider  w="70%" mt="10px"/>

  




          <Flex
          bg='white'
          borderRadius='5px'
          mt="10px"
          px="10px"

          >

  <div style={{ textAlign: 'left', fontWeight:  '600'}}>
  <Text as=''>{textinfo}</Text>
</div>



</Flex>


          





        </Box>



      </Flex>
    </Card>
  );
}
