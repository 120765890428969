// Chakra imports
import { useState } from "react";
import ReactApexChart from "react-apexcharts"; // Corrected import statement
import { Flex } from "@chakra-ui/react";



// Custom components

// Assets
export default function GeneralInformation(props) {
  const { ...rest } = props;
  // Chakra Color Mode


  // Chart options and data
  const [chartData, ] = useState({


    options: { chart: {
      height: 450,
      toolbar: {
        show: false,
      },
   
        type: 'line'
      },
      title: {
        text: '',
        align: 'right',
        margin: -22,
        offsetX: -15,
        offsetY: 11,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  '600',
          fontFamily:  undefined,
          color:  '#263238'
        },

      },
      colors: ['#fff', '#2c3cd4'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        type: "line", 
        width: [5, 5],
       
        dashArray: [0, 0],
        
      },
      markers: {
        size: 0,
        fillColor: '',
        strokeColor: '',
        shape: "square" // "circle" | "square" | "rect"
      },
      
      
      yaxis: {
        show: true,
        showAlways: false,
        showForNullSeries: false,
        seriesName: undefined,
        opposite: true,
        reversed: false,
        logarithmic: false,
        logBase: 10,
        tickAmount: 2,
        min: 100,
        max: 1000,
        stepSize: 0,
        forceNiceScale: true,
        floating: false,
        decimalsInFloat: 3,
        labels: {
            show: false,
            align: 'right',
            minWidth: 5,
            maxWidth: 50,
            style: {
                colors: [],
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: '#fff',
                cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 8,
            offsetY: 0,
            rotate: 0,
        },
        axisBorder: {
            show: false,
            color: '#fff',
            offsetX: -11,
            width: 4,
            offsetY: 0
        },
        axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#fff',
                width: 3,
                dashArray: 0,
            },
        },
        tooltip: {
            enabled: false,
            offsetX: 10,
        },
        
    },



      legend: {
        position: 'top',
        size: 1,
        horizontalAlign: 'right',
        floating: false,
        offsetY: 25,
        offsetX: 10,
      },

      xaxis: {     
        type: "date",
        categories: [
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          // Add more years as needed
        ],
        labels: {
          show: false,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
              colors: [],
              fontSize: '11px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 500,
              cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 2,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
          },
      },
        
        
        axisBorder: {
          show: false,
          color: '#78909C',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
      },
      

      },













      // Add your chart options here
      grid: {
        show: false,
        strokeDashArray: 1,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      annotations: {
        yaxis: [{
          y: 54,
          borderColor: '',
          label: {
            borderColor: '',
            style: {
              color: '#fff',
              background: '#000',
            },
            text: '',
          }
        }, 
        ],
        xaxis: [{
          x:('0'),
          strokeDashArray: 0,
          borderColor: '',
          label: {
            borderColor: '#775DD0',
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: 'z',
          }
        }, {
          x: ('2019'),
          x2: ('2021'),
          fillColor: '#20B6EA',
          opacity: 0.1,
          label: {
            borderColor: '',
            style: {
              fontSize: '10px',
              color: '#fff',
              background: '#00E396',
            },
            offsetY: -10,
            text: ' ',
          }
        }],
        points: [
          
          {
          x: ('2017'),
          y: 82,
          marker: {
            size: 0,
            fillColor: 'black',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          },
          label: {
            borderColor: '',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '',
            },
      
            text: '',
          }
        }, 

               
        {
          x: ('2019'),
          y: 100,
          marker: {
            size: 0,
            fillColor: 'black',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          },
          label: {
            borderColor: '',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '',
            },
      
            text: '',
          }
        },]
      },
      // Grid

    },
    series: [
        {
            name: 'A',
            data: [1000, 1651, 1073, 1041, 1136, 1524, 1138]
          }, {
            name: 'B',
            data: [462, 689, 753, 741, 1136, 824, 638]
          },
    ],

  });

  return (
    


<Flex backgroundColor='inherit' borderRadius='5px' direction="column" flex="1" justifyContent="center" >

        


          {/* ReactApexChart component */}
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height="100%"
            width="100%"
          />



      </Flex>
   
  );
}




