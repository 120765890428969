import { Grid, Icon } from "@chakra-ui/react";
import { MdLineAxis, MdDashboard } from "react-icons/md";
import { Button } from "@chakra-ui/react";
import Chart from "views/admin/analyse/components/chart";
import Chart2 from "views/admin/analyse/components/chart2";
import Chart3 from "views/admin/analyse/components/chart3";
import Chart4 from "views/admin/analyse/components/chart4";
import Chart5 from "views/admin/analyse/components/chart5";
import Chart6 from "views/admin/analyse/components/chart6";

import {
  Box,
  Flex,
  Stack,
  Badge,
  SimpleGrid,

  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { Link } from "react-router-dom";




export default function Project(props) {
  const { title, link, link2, chart, tag1, tag2, cardcolor, textcolor, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");

  let chartComponent;
  switch (chart) {
    case "1":
      chartComponent = <Chart />;
      break;
    case "2":
      chartComponent = <Chart2 />;
      break;
    // Add more cases for additional chart types if needed
    case "3":
      chartComponent = <Chart3 />;
      break;
      case "4":
        chartComponent = <Chart4 />;
        break;
      case "5":
        chartComponent = <Chart5 />;
        break;     
      case "6":
        chartComponent = <Chart6 />;
        break;             
    default:
      chartComponent = null;
  }

  return (
<Card
  backgroundColor={cardcolor}
  height="100%"
  px="10px"
  py="10px"
  width="100%"
  mb={{ base: "20px", lg: "10px" }}
  mr={{ base: "10px", lg: "10px" }}
  style={{
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
    borderBottomLeftRadius: '15px',
  }}
  borderWidth='0px'
  borderShadow='gray.300'
>

<Link fontWeight="500" color={brandColor} to={link} fontSize="sm">
        <Text
          color={textcolor}
          fontWeight="600"
          fontSize="18px"
          textAlign="left"
          mb="5px"
          ml="10px"
        >
          {title}
        </Text>
      </Link>
      <Link to={link}>
        {chartComponent}
      </Link>
        <Flex
          as="button"
          gap="15px"
          mt="10px"
          align="right"
          bg="inherit"
          justifyContent="center" 
          style={{
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            borderBottomLeftRadius: '15px',
          }}
          mb="0px" // Adjusted mb property
        >    
          <Link fontWeight="500" color={brandColor} to={link} fontSize="lg" style={{ width: '100%' }}>
            <Button  
              w="100%"
              style={{
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
                borderBottomLeftRadius: '15px',
              }}
              mr="20px" 
              px="15px" 
              bg="gray.200" 
              color="white"  
              size="md"
            >
              <Text color="black">Bekijk meer</Text>
            </Button>
          </Link>
        </Flex>

</Card>

  );
}
