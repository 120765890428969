
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'



import { Box, Text, Flex, Link, useColorModeValue, SimpleGrid, BreadcrumbLink, Breadcrumb,
  BreadcrumbItem,  } from "@chakra-ui/react";
import React from "react";
import DashboardAll from "views/admin/energiedash/components/dashboardsall";
import MainDash from "views/admin/energiedash/components/maindashallASI";
import EnergieApp2 from "views/admin/dashboard/components/appenergie2";
import Homepageblock2 from "views/admin/profile/components/homepageblock2";
import Homepageblock3 from "views/admin/profile/components/homepageblock3";
import Homepagedeals from "views/admin/profile/components/homepagedeals";

import Card from "components/card/Card";
import Logos from "views/admin/profile/components/logos";
import Logos2 from "views/admin/profile/components/logos2";

import { ChevronRightIcon, } from '@chakra-ui/icons'



export default function Settings() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <SimpleGrid 
      columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='20px'
      >    



    

    </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='10px'>

      <Homepageblock3/>

    <Homepagedeals/>




      <Card
      direction='column'
      w='100%'
      h='120px'
      px='0px'
      borderRadius='5px'
      overflowX={{ sm: "hidden", lg: "hidden" }}>
      </Card>



      </SimpleGrid>

    </Box>
  );
}
