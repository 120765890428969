import { Icon } from "@chakra-ui/react";
import { MdLineAxis, MdDashboard, MdTableRows, MdTabUnselected, MdTableChart, MdInfo } from "react-icons/md";
import { Button, Tabs,
  Stack,
  Divider,
  AbsoluteCenter,
  Badge,
  Center,
  SimpleGrid,
  Spacer,
  TabList,
  Tab,
  Grid,
  Progress,
  CircularProgress,
  CircularProgressLabel,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  GridItem,
  Tooltip,
  StatGroup,
  TabPanels,
  TableContainer,
  Table,
  TableCaption,
  Tbody,
  Tr,
  Td,
  TabPanel, } from "@chakra-ui/react";
import Chart from "views/admin/analyse/components/chart";
import Chart2 from "views/admin/analyse/components/chart2";
import Chart3 from "views/admin/energiedash/components/chart3";
import Chart3B from "views/admin/energiedash/components/chart3B";
import ChartArea from "views/admin/energiedash/components/chartarea";
import ChartArea2 from "views/admin/energiedash/components/chartarea2";

import ChartA1 from "views/admin/analyse/components/chartA1";

import LogoIMG from "assets/img/layout/aerilogo3.png";



import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Project(props) {
  const { 
  DashPar0,
  DashPar1,
  DashPar2,
  DashPar3,
  DashPar4,
  DashPar5,
  DashPar6,
  DashPar7,
  DashPar5A,
  DashPar6A,
  DashPar7A,
  DashPar1B,
  DashPar2B,
  DashPar3B,
  DashPar4B,
  DashPar1C,
  DashPar2C,
  DashPar3C,
  DashPar4C,
  DashPar0B,
  DashPar0C,
  title,
  titleMD,
  MDbgimage,
  link, 
  ink2, 
  chart, 
  chart2, 
  textinfo, 
  CirPro1, 
  CirProTag1, 
  CirProTag2, 
  CirProTag3, 
  CirProTag4, 
  tableRow1,
  tableRow1A,
  tableRow2,
  tableRow2A,
  tableRow3,
  tableRow3A,
  tableRow1B,
  tableRow1AB,
  tableRow2B,
  tableRow2AB,
  tableRow3B,
  tableRow3AB,

  tag1, tag2, 
  tag3, ...rest } = props;
  const cardcolor = "gray.900";
  const txtc = "gray.200";
  const txtc2 = "gray.400";
  const bdgc1 = "green";
  const bdgc2 = "blue";
  const bdgc3 = "gray";
  const bordercolor = "gray.500";
  const textcolor = "gray.200";
  const bg = useColorModeValue("white", "navy.700");

  let chartComponent2;
  switch (chart2) {
    case "1":
      chartComponent2 = <Chart />;
      break;
    case "2":
      chartComponent2 = <Chart2 />;
      break;
    // Add more cases for additional chart types if needed
    case "3":
      chartComponent2 = <Chart3 />;
      break;
    case "3B":
      chartComponent2 = <Chart3B />;
      break;
    case "A1":
      chartComponent2 = <ChartA1 />;
      break;
    case "chartarea":
      chartComponent2 = < ChartArea />;
      break;
    case "chartarea2":
      chartComponent2 = < ChartArea2 />;
      break;
       
        
      



    default:
      chartComponent2 = null;
  }

  let chartComponent;
  switch (chart) {
    case "1":
      chartComponent = <Chart />;
      break;
    case "2":
      chartComponent = <Chart2 />;
      break;
    // Add more cases for additional chart types if needed
    case "3":
      chartComponent = <Chart3 />;
      break;
    case "A1":
        chartComponent = <ChartA1 />;
        break;
    case "chartarea":
      chartComponent2 = < ChartArea />;
      break;
    case "chartarea2":
      chartComponent2 = < ChartArea2 />;
      break;
       
      
      



    default:
      chartComponent = null;
  }

  return (
    <Card
      h="100%"
      py='0px'
      px='0px'
      borderRadius="5px"
      backgroundColor={cardcolor}
    >
            <Image
  h='50px'
  mt='0px'
  width='100%'
  opacity='100%'
  objectFit='cover'
  src={MDbgimage}
  style={{
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
  }}
/>

        <Flex  
                  alignItems='center'

               justifyContent="center"
        direction='column' mt={{ base: "0px", md: "0px", sm: "0px" }}>
          




          <Flex 
          borderRadius='4px'
          mt='10px'
          w='50%'
          h='40px'
          bg={cardcolor}
          alignItems='center'
          justifyContent="center"
          direction='column'>

          

            <Text
              color='white'
              fontWeight="800"
              fontSize="25px"
              textAlign="center"
              mb="0px"
            >
             {titleMD} 
            </Text>

          </Flex>


     
    <Flex 
   direction='column'
   justifyContent="center"
   alignItems='center'
    bg={cardcolor}>


<Flex 
 bg=''
 alignItems='center'
 maxWidth='100%'
 w='80%'
 justifyContent="center"
 direction='row'
> 

<TableContainer  mt='40px' width="50%" overflow="hidden">
  <Table variant='simple' size="sm" borderColor={bordercolor}>
    <TableCaption></TableCaption>
    <Tbody>
      {/* Conditional rendering for each row */}
      <Tr borderColor={bordercolor}>
        <Td w='100px' borderColor={bordercolor}>
          <Text as='' color={textcolor}>{tableRow1}</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text as='' color={textcolor}>
      {tableRow1A}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>{tableRow2}</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
          {tableRow2A}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>      {tableRow3}</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
          {tableRow3A}  
          </Text>
        </Td>
      </Tr>

      










    </Tbody>
  </Table>
</TableContainer>
<Center  width='10px' height='10px'>
</Center> 
<TableContainer  mt='40px' width="50%" overflow="hidden">
  <Table variant='simple' size="sm" borderColor={bordercolor}>
    <TableCaption></TableCaption>
    <Tbody>
      {/* Conditional rendering for each row */}
      <Tr borderColor={bordercolor}>
        <Td w='100px' borderColor={bordercolor}>
          <Text as='' color={textcolor}>{tableRow1B}</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text as='' color={textcolor}>
      {tableRow1AB}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>{tableRow2B}</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
          {tableRow2AB}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>      {tableRow3B}</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
          {tableRow3AB}  
          </Text>
        </Td>
      </Tr>

      










    </Tbody>
  </Table>
</TableContainer>
</Flex>





<Flex
 w='100%'
 bg='gray.200'
 borderRadius="5px"

 justifyContent="left"
>  

<Tabs 
size='xs' 
 colorScheme='green'
isFitted variant='unstyled'>
  <TabList 
   colorScheme='green'
  mb='1px'>
  <Tab 
  borderRadius='0px'
  _selected={{ color: 'white', bg: 'gray.800' }}>economic</Tab>
  <Tab 
  borderRadius='0px'
  _selected={{ color: 'white', bg: 'gray.800' }}>finance</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>
<Flex
 gap="10px"

> 

<Flex

 bg='gray.800'
 justifyContent=""
 direction='column'


> 






<Box

 justifyContent="center"
 bg='gray.200'
>  
<StatGroup
 justifyContent="center"
>
  <Stat>
    <StatLabel>Average GDP</StatLabel>
    <StatNumber>345,670</StatNumber>
    <StatHelpText>
      <StatArrow type='increase' />
      23.36%
    </StatHelpText>
  </Stat>

  <Stat>
    <StatLabel>Imports & Exports</StatLabel>
    <StatNumber>45</StatNumber>
    <StatHelpText>
      <StatArrow type='decrease' />
      2.05%
    </StatHelpText>
  </Stat>
</StatGroup>
</Box>












    <Flex 
        gap='15px' 
        py='15px'    >


        <Flex  w='100%' gap='10px' alignItems='center' justifyContent="flex-end">  
  <Flex textAlign="right" direction='column'> 
    <Badge fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>
      {CirProTag1}
    </Badge>
    <Stat mt='5px' color='white' >
      <StatNumber  color={txtc} fontSize='18px' >
        {CirProTag3}
        <Tooltip label={CirProTag4} aria-label='A tooltip'>
          <StatLabel><Text color={txtc2} as='b'>{CirProTag2}</Text></StatLabel>
        </Tooltip>
      </StatNumber>
    </Stat>
  </Flex>
  <CircularProgress  colorScheme='white' thickness='10px' color='green.300' value={CirPro1} size='65px' >
    <CircularProgressLabel  color={txtc}><Text as='b'>{CirPro1}%</Text></CircularProgressLabel>
  </CircularProgress>
</Flex>


          
<Center height='100px'>
  <Divider orientation='vertical' />
</Center>         
      


          <Flex    w='100%' gap='10px'  direction='row' alignItems='center'>
          
            <CircularProgress  thickness='10px' color='green.400' value={DashPar0} size='65px' >
                <CircularProgressLabel  color={txtc}><Text as='b'>{DashPar0}%</Text></CircularProgressLabel>
            </CircularProgress>
            


        <Flex textAlign="left" direction='column'> 

        <Badge w='100%' fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>

        {DashPar2}</Badge>

            <Stat mt='5px' color='white' >
            <StatNumber  color={txtc} fontSize='18px' >

            {DashPar1}

            <Tooltip label={DashPar4} aria-label='A tooltip'>


            
            <StatLabel><Text color={txtc2} as='b'>{DashPar3}</Text></StatLabel>
        </Tooltip>



            </StatNumber>



          </Stat>
        </Flex>



          </Flex>
      
         </Flex>


</Flex>

<Flex

 bg='green.900'
 justifyContent=""
 direction='column'


> 






<Box

 justifyContent="center"
 bg='gray.300'
>  
<StatGroup
 justifyContent="center"
>
  <Stat>
    <StatLabel>Spending</StatLabel>
    <StatNumber>345,670</StatNumber>
    <StatHelpText>
      <StatArrow type='increase' />
      23.36%
    </StatHelpText>
  </Stat>

  <Stat>
    <StatLabel>Investments</StatLabel>
    <StatNumber>450 million</StatNumber>
    <StatHelpText>
      <StatArrow type='increase' />
      8.05%
    </StatHelpText>
  </Stat>
</StatGroup>
</Box>












    <Flex 
        gap='15px' 
        py='15px'    >


        <Flex  w='100%' gap='10px' alignItems='center' justifyContent="flex-end">  
  <Flex textAlign="right" direction='column'> 
    <Badge fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>
      {CirProTag1}
    </Badge>
    <Stat mt='5px' color='white' >
      <StatNumber  color={txtc} fontSize='18px' >
        {CirProTag3}
        <Tooltip label={CirProTag4} aria-label='A tooltip'>
          <StatLabel><Text color={txtc2} as='b'>{CirProTag2}</Text></StatLabel>
        </Tooltip>
      </StatNumber>
    </Stat>
  </Flex>
  <CircularProgress  colorScheme='white' thickness='10px' color='green.300' value={CirPro1} size='65px' >
    <CircularProgressLabel  color={txtc}><Text as='b'>{CirPro1}%</Text></CircularProgressLabel>
  </CircularProgress>
</Flex>


          
<Center height='100px'>
  <Divider orientation='vertical' />
</Center>         
      


          <Flex    w='100%' gap='10px'  direction='row' alignItems='center'>
          
            <CircularProgress  thickness='10px' color='green.400' value={DashPar0} size='65px' >
                <CircularProgressLabel  color={txtc}><Text as='b'>{DashPar0}%</Text></CircularProgressLabel>
            </CircularProgress>
            


        <Flex textAlign="left" direction='column'> 

        <Badge w='100%' fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>

        {DashPar2}</Badge>

            <Stat mt='5px' color='white' >
            <StatNumber  color={txtc} fontSize='18px' >

            {DashPar1}

            <Tooltip label={DashPar4} aria-label='A tooltip'>


            
            <StatLabel><Text color={txtc2} as='b'>{DashPar3}</Text></StatLabel>
        </Tooltip>



            </StatNumber>



          </Stat>
        </Flex>



          </Flex>
      
         </Flex>


</Flex>

</Flex>
    </TabPanel>
    <TabPanel>

    <Flex
 gap="10px"

> 

<Flex

 bg='gray'
 justifyContent=""
 direction='column'


> 






<Box

 justifyContent="center"
 bg='gray.100'
>  
<StatGroup
 justifyContent="center"
>
  <Stat>
    <StatLabel>AVG</StatLabel>
    <StatNumber>345,670</StatNumber>
    <StatHelpText>
      <StatArrow type='increase' />
      23.36%
    </StatHelpText>
  </Stat>

  <Stat>
    <StatLabel>Imports & Exports</StatLabel>
    <StatNumber>45</StatNumber>
    <StatHelpText>
      <StatArrow type='decrease' />
      2.05%
    </StatHelpText>
  </Stat>
</StatGroup>
</Box>












    <Flex 
        gap='15px' 
        py='15px'    >


        <Flex  w='100%' gap='10px' alignItems='center' justifyContent="flex-end">  
  <Flex textAlign="right" direction='column'> 
    <Badge fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>
      {CirProTag1}
    </Badge>
    <Stat mt='5px' color='white' >
      <StatNumber  color={txtc} fontSize='18px' >
        {CirProTag3}
        <Tooltip label={CirProTag4} aria-label='A tooltip'>
          <StatLabel><Text color={txtc2} as='b'>{CirProTag2}</Text></StatLabel>
        </Tooltip>
      </StatNumber>
    </Stat>
  </Flex>
  <CircularProgress  colorScheme='white' thickness='10px' color='green.300' value={CirPro1} size='65px' >
    <CircularProgressLabel  color={txtc}><Text as='b'>{CirPro1}%</Text></CircularProgressLabel>
  </CircularProgress>
</Flex>


          
<Center height='100px'>
  <Divider orientation='vertical' />
</Center>         
      


          <Flex    w='100%' gap='10px'  direction='row' alignItems='center'>
          
            <CircularProgress  thickness='10px' color='green.400' value={DashPar0} size='65px' >
                <CircularProgressLabel  color={txtc}><Text as='b'>{DashPar0}%</Text></CircularProgressLabel>
            </CircularProgress>
            


        <Flex textAlign="left" direction='column'> 

        <Badge w='100%' fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>

        {DashPar2}</Badge>

            <Stat mt='5px' color='white' >
            <StatNumber  color={txtc} fontSize='18px' >

            {DashPar1}

            <Tooltip label={DashPar4} aria-label='A tooltip'>


            
            <StatLabel><Text color={txtc2} as='b'>{DashPar3}</Text></StatLabel>
        </Tooltip>



            </StatNumber>



          </Stat>
        </Flex>



          </Flex>
      
         </Flex>


</Flex>

<Flex

 bg='gray.200'
 justifyContent=""
 direction='column'


> 






<Box

 justifyContent="center"
 bg='gray.300'
>  
<StatGroup
 justifyContent="center"
>
  <Stat>
    <StatLabel>Average GDP</StatLabel>
    <StatNumber>345,670</StatNumber>
    <StatHelpText>
      <StatArrow type='increase' />
      23.36%
    </StatHelpText>
  </Stat>

  <Stat>
    <StatLabel>Imports & Exports</StatLabel>
    <StatNumber>45</StatNumber>
    <StatHelpText>
      <StatArrow type='decrease' />
      2.05%
    </StatHelpText>
  </Stat>
</StatGroup>
</Box>












    <Flex 
        gap='15px' 
        py='15px'    >


        <Flex  w='100%' gap='10px' alignItems='center' justifyContent="flex-end">  
  <Flex textAlign="right" direction='column'> 
    <Badge fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>
      {CirProTag1}
    </Badge>
    <Stat mt='5px' color='white' >
      <StatNumber  color={txtc} fontSize='18px' >
        {CirProTag3}
        <Tooltip label={CirProTag4} aria-label='A tooltip'>
          <StatLabel><Text color={txtc2} as='b'>{CirProTag2}</Text></StatLabel>
        </Tooltip>
      </StatNumber>
    </Stat>
  </Flex>
  <CircularProgress  colorScheme='white' thickness='10px' color='green.300' value={CirPro1} size='65px' >
    <CircularProgressLabel  color={txtc}><Text as='b'>{CirPro1}%</Text></CircularProgressLabel>
  </CircularProgress>
</Flex>


          
<Center height='100px'>
  <Divider orientation='vertical' />
</Center>         
      


          <Flex    w='100%' gap='10px'  direction='row' alignItems='center'>
          
            <CircularProgress  thickness='10px' color='green.400' value={DashPar0} size='65px' >
                <CircularProgressLabel  color={txtc}><Text as='b'>{DashPar0}%</Text></CircularProgressLabel>
            </CircularProgress>
            


        <Flex textAlign="left" direction='column'> 

        <Badge w='100%' fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>

        {DashPar2}</Badge>

            <Stat mt='5px' color='white' >
            <StatNumber  color={txtc} fontSize='18px' >

            {DashPar1}

            <Tooltip label={DashPar4} aria-label='A tooltip'>


            
            <StatLabel><Text color={txtc2} as='b'>{DashPar3}</Text></StatLabel>
        </Tooltip>



            </StatNumber>



          </Stat>
        </Flex>



          </Flex>
      
         </Flex>


</Flex>

</Flex>





    </TabPanel>
  </TabPanels>
</Tabs>




</Flex>











  <Divider mt='30px' w='80%'/>



      <Flex  
       px='10px'
       py='10px'
      bg="" 
      w='100%'
      direction='column'
      justifyContent="center">

          <SimpleGrid columns={1} spacing={0}> 

      <Flex bg='' textAlign="right"   gap='10px' mt='10px'  direction='row' alignItems='center'>  
      
          <Flex textAlign="left" direction='column'> 
          

          </Flex>

          <Progress  colorScheme={bdgc1} borderRadius='0px' height='10px' w='100%'  value={DashPar5A} />
          <Text color={txtc} as='b'> {DashPar5A}%</Text>
      </Flex>

        <Flex  bg='' gap='10px' mt='10px'  direction='row' alignItems='center'>  
      
          <Flex textAlign="left" direction='column'> 
          

          </Flex>

          <Progress  colorScheme={bdgc3} borderRadius='0px' height='10px' w='100%'  value={DashPar6A} />
          <Text color={txtc} as='b'> {DashPar6A}%</Text>
      </Flex>

          </SimpleGrid>
        
        
<Flex 
mt='10px' 
      >
        










     
    </Flex>
      

    <Flex 


width='100%'   height='200px'  bg=''> 
{chartComponent2}
</Flex>



      
                <Flex 
          mt="10px"      
          alignItems='center'
          justifyContent="center"
          direction='column'>
          
            <Image
             mb="10px"     
             src={LogoIMG} w="60px" objectFit="contain" />
<Text color={txtc} as='sub'>  </Text>
 

          </Flex>
      

      
      
      
      
      </Flex>





    </Flex>



  </Flex>
  <Image
  h='50px'
  mt='0px'
  width='100%'
  opacity='100%'
  objectFit='cover'
  src={MDbgimage}
  style={{
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
  }}
/>


 
</Card>
  );
}
