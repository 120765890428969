// Chakra imports
import { useState } from "react";
import ReactApexChart from "react-apexcharts"; // Corrected import statement
import { Flex } from "@chakra-ui/react";



// Custom components

// Assets
export default function GeneralInformation(props) {
  const { 
    DashPar0,
    DashPar1,
    DashPar2,
    DashPar3,
    DashPar4,
    title,
    link, 
    ink2, 
    chart, 
    textinfo, 
    CirPro1, 
    CirProTag1, 
    CirProTag2, 
    CirProTag3, 
    CirProTag4, 
    tag1, tag2, 
    tag3, ...rest } = props;
  // Chakra Color Mode


  // Chart options and data
  const [chartData, ] = useState({


    options: { chart: {
      height: 450,
      toolbar: {
        show: false,
      },
   
        type: 'line'
      },
      title: {
        text: ' ',
        align: 'right',
        margin: -22,
        offsetX: -15,
        offsetY: 30,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  '600',
          fontFamily:  undefined,
          color:  '#263238'
        },
      }, 
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: ['#2c343c', '#5664dc', '#000', ],
        width: [3, 3, 3],      
        dashArray: [0, 0, 0],
        
      },
      markers: {
        size: 0,
        fillColor: '#e3e3e3',
        strokeColor: '',
        shape: "square" // "circle" | "square" | "rect"
      },
      
      
      yaxis: {
        show: false,
        title: {
          text: ''
        },
        min: 25,
        max: 120
      },
      legend: {
        position: 'top',
        size: 1,
        horizontalAlign: 'right',
        floating: false,
        offsetY: 5,
        offsetX: -15,
      },

      xaxis: {     
        type: "date",
        categories: [
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          // Add more years as needed
        ],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
              colors: [],
              fontSize: '11px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 500,
              cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 2,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
          },
      },
        
        
        axisBorder: {
          show: false,
          color: '#78909C',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
      },
      

      },






      
      tooltip: {
        x: {
          format: 'yy'
        },
      },
      // Add your chart options here
      grid: {
        show: false,
        strokeDashArray: 1,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      annotations: {
        yaxis: [{
         
          y: 0,
          borderColor: '',
          label: {
            borderColor: '',
            style: {
              color: '',
              size: 2,
              background: '',
            },
            text: '',
          }
        }, 
        ],
        xaxis: [{
          x:('0'),
          strokeDashArray: 3,
          borderColor: '',
          label: {
            borderColor: '#775DD0',
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: 'z',
          }
        }, {
          x: ('2018'),
          x2: ('2021'),
          fillColor: '#20B6EA',
          opacity: 0.1,
          label: {
            borderColor: '',
            style: {
              fontSize: '10px',
              color: '#fff',
              background: '#00E396',
            },
            offsetY: -10,
            text: ' ',
          }
        }],
        points: [
          
          {
          x: ('2017'),
          y: 82,
          marker: {
            size: 1,
            fillColor: 'black',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          },
          label: {
            borderColor: '',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '',
            },
      
            text: '',
          }
        }, 

               
        {
          x: ('2019'),
          y: 100,
          marker: {
            size: 1,
            fillColor: 'black',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          },
          label: {
            borderColor: '',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '',
            },
      
            text: '',
          }
        },]
      },
      // Grid

    },
    series: [
      {
        name: 'A',
        data: [31, 40, 58, 51, 42, 109, 100]
      },
      {
        name: 'B',
        data: [60, 50, 45, 32, 34, 52, 41]
      },

    ],
  });

  return (
<Flex backgroundColor='blue.50' borderRadius='5px' direction="column" flex="1" justifyContent="center" >

        


{/* ReactApexChart component */}
<ReactApexChart
  options={chartData.options}
  series={chartData.series}
  type="line"
  height="100%"
  width="100%"
/>



</Flex>
  );
}
