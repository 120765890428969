/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box,  Button, Flex, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dashboard/components/DevelopmentTable";
import {
  columnsDataDevelopment,
} from "views/admin/dashboard/variables/columnsData";
import tableDataDevelopment from "views/admin/dashboard/variables/tableDataDevelopment.json";
import React from "react";


import DashboardAll from "views/admin/dashboard/components/dashboardsall";
import Logos from "views/admin/profile/components/logos";


import { Link } from 'react-router-dom';






import Card from "components/card/Card";



export default function Settings() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='0px'>
   
   
      <DashboardAll/>


      </SimpleGrid>




  



      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='10px'>

      <Card
      w='100%'
      h='120px'
      px='0px'
      borderRadius='5px'>

<Logos/>

      </Card>

      <Card
      direction='column'
      w='100%'
      h='100px'
      px='0px'

      borderRadius='5px'
      overflowX={{ sm: "hidden", lg: "hidden" }}>
      </Card>
      
      <Card
      direction='column'
      w='100%'
      h='100px'
      px='0px'
      borderRadius='5px'
      overflowX={{ sm: "hidden", lg: "hidden" }}>
      </Card>


      </SimpleGrid>







    </Box>
  );
}
