
import Homepageblock from "views/admin/profile/components/homepageblock";
import Homepageblock2 from "views/admin/profile/components/homepageblock2";
import Card from "components/card/Card";
import Logos from "views/admin/profile/components/logos";
import Logos2 from "views/admin/profile/components/logos2";
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink, Box, Select, Flex, SimpleGrid, Breadcrumb, BreadcrumbItem } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardAll from 'views/admin/energiedash/components/dashboardsall';
import MainDash from 'views/admin/energiedash/components/maindashall';

export default function Settings() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  useEffect(() => {
    axios.get('http://localhost:8080/getRegion')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  // Find the ID of the user representing Africa
  const africaUser = users.find(user => user.name === 'Africa');
  // Set the initial state of selectedUser to the ID of the user representing Africa
  useEffect(() => {
    if (africaUser) {
      setSelectedUser(africaUser._id);
    }
  }, [users]); // Update selectedUser when users data changes


  return (
    <Box pt={{ base: '60px', md: '80px', xl: '80px' }}>
      <Flex mb="10px">
        <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <ChakraLink as={ReactRouterLink} color="gray.500" to="./dashboards">Dashboards</ChakraLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <ChakraLink as={ReactRouterLink} color="gray.800" to="./dashboardA">Regional Dashboard - Africa</ChakraLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <Flex>
  <Select
    bg="white"
    color="gray"
    placeholder="Select country"
    size="md"
    onChange={handleUserChange}
    value={selectedUser}
    style={{ display: 'none' }} // Set the display property to 'none'
  >
    {users.map(user => (
      <option key={user._id} value={user._id}>
        {user.name}
      </option>
    ))}
  </Select>
</Flex>



      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="10px" mb="0px">


        <MainDash selectedUser={selectedUser} />
        <DashboardAll selectedUser={selectedUser} />
      </SimpleGrid>

      
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='0px'>
    


      </SimpleGrid>







      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='10px' mb='10px'>
      <Homepageblock/>

      <Homepageblock2/>
      
  


      </SimpleGrid>


      <Card
      direction='column'
      w='100%'
      h='120px'
      px='0px'
      borderRadius='5px'
      overflowX={{ sm: "hidden", lg: "hidden" }}>




<Logos2/>

      </Card>




    </Box>
  );
}
