// Chakra imports
import { Spacer, Image, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import General from "views/admin/profile/components/General";
import { Link } from "react-router-dom";



export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    
    
    <Flex 
    alignItems='center'
    direction='column'> 
    

    
      <Flex 
      width='100%'
      direction='row'>        
        <Image
        mt='12px'
        boxSize='45px'
        objectFit='contain'
        src='https://www.easyswitch.nl/assets/Raedthuys-Pure-Energie.png'>          
        </Image>
        
 
        <Spacer />

        <Image
        boxSize='75px'
        objectFit='contain'
        src='https://upload.wikimedia.org/wikipedia/commons/b/b0/Eneco_logo.png'>          
        </Image>

        <Spacer />

        <Link to='./dashboardDev'>
        <Image
        boxSize='85px'
        objectFit='contain'
        src='https://www.overstappen.nl/assets/energiedirect-logo.png'>          
        </Image>
        </Link>

        <Spacer />

        <Image
        boxSize='75px'
        objectFit='contain'
        src='https://banner2.cleanpng.com/20180504/pfq/kisspng-engie-logo-natural-gas-energy-electrabel-mobilization-5aec434d27fb85.3225816515254331651638.jpg'>          
        </Image>

      </Flex>

      </Flex>
 
  );
}
