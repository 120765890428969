// Chakra imports
import { useState } from "react";
import ReactApexChart from "react-apexcharts"; // Corrected import statement
import { Flex } from "@chakra-ui/react";



// Custom components

// Assets
export default function GeneralInformation(props) {
  const { ...rest } = props;
  // Chakra Color Mode


  // Chart options and data
  const [chartData, ] = useState({


    options: { chart: {
      height: 450,
      toolbar: {
        show: false,
      },
   
        type: 'line'
      },
      title: {
        text: '',
        align: 'right',
        margin: -18,
        offsetX: -15,
        offsetY: 30,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  '600',
          fontFamily:  undefined,
          color:  '#263238'
        },

      },
      colors: ['#2c343c', '#9a9a9a'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        type: "line", 
        width: [5, 4],
       
        dashArray: [0, 2],
        
      },
      markers: {
        size: 3,
        fillColor: '#e3e3e3',
        strokeColor: '',
        shape: "square" // "circle" | "square" | "rect"
      },
      
      
      yaxis: {
        title: {
          text: ''
        },
        min: 6000,
        max: 15000,
      },
      legend: {
        position: 'top',
        size: 1,
        horizontalAlign: 'right',
        floating: false,
        offsetY: 22,
        offsetX: -15,
      },

      xaxis: {
        type: "date",
        categories: [

          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
    
          // Add more years as needed
        ]
      },
      tooltip: {
        x: {
          format: 'yy'
        },
      },
      // Add your chart options here
      grid: {
        show: false,
        strokeDashArray: 1,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      annotations: {
        yaxis: [{
          y: 54,
          borderColor: '#000',
          label: {
            borderColor: '',
            style: {
              color: '#fff',
              background: '#000',
            },
            text: '',
          }
        }, 
        ],
        xaxis: [{
          x:('0'),
          strokeDashArray: 3,
          borderColor: '',
          label: {
            borderColor: '#775DD0',
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: 'z',
          }
        }, {
          x: ('2020'),
          x2: ('2021'),
          fillColor: '#20B6EA',
          opacity: 0.1,
          label: {
            borderColor: '',
            style: {
              fontSize: '10px',
              color: '#fff',
              background: '#00E396',
            },
            offsetY: -10,
            text: ' ',
          }
        }],
        points: [
          
          {
          x: ('2017'),
          y: 82,
          marker: {
            size: 3,
            fillColor: 'black',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          },
          label: {
            borderColor: '',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '',
            },
      
            text: '',
          }
        }, 

               
        {
          x: ('2019'),
          y: 100,
          marker: {
            size: 3,
            fillColor: 'black',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          },
          label: {
            borderColor: '',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '',
            },
      
            text: '',
          }
        },]
      },
      // Grid

    },
    series: [
        {
            name: 'east-africa',
            data: [10070, 10651, 10793, 10741, 11136, 11524, 11638]
          }, {
            name: 'west-africa',
            data: [4562, 6589, 7953, 9741, 10136, 8524, 6638]
          },
    ],

  });

  return (
    


<Flex backgroundColor='white.50' borderRadius='5px' direction="column" flex="1" justifyContent="center" >

        


          {/* ReactApexChart component */}
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height="100%"
            width="100%"
          />



      </Flex>
   
  );
}




