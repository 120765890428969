import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { 
  TableContainer,
  Table,
  TableCaption,
  Tr,
  Td,
  Text,
  Divider,
  Tbody,
  Select,
  Flex,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Box,
  AbsoluteCenter,
  CircularProgress,
  CircularProgressLabel,
  Spacer,

 } from '@chakra-ui/react';





export default function Project(props) {
  
  const [users, setUsers] = useState([]);
  
  
  // Find the ID of the user representing France
  const franceUser = users.find(user => user.name === 'Finland');
  // Set the initial state of selectedUser to the ID of the user representing France
  const [selectedUser, setSelectedUser] = useState(franceUser ? franceUser._id : '');

  // Define your handleUserChange function
  const handleUserChange = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);
  };



  useEffect(() => {
        // Fetch data from the http://localhost/8080/getCountry'

    // Fetch data from the server endpoint LINK: https://aeri-api.onrender.com/getCountry'
    axios.get('http://localhost:8080/getCountry')
      .then(response => {
        // Update the state with the fetched data
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []); // Empty dependency array to run only once when the component mounts


  const cardcolor = "gray.100";
  const bordercolor = "gray.400";
  const textcolor = "black";
  return (
<Flex> 

<Card
  borderRadius="5px"
  backgroundColor={cardcolor}
  mb={{ base: "10px", lg: "10px" }}
  mr={{ base: "10px", lg: "10px" }}
  align="center"
>







<Select
        bg='white'
        color='gray'
        placeholder='Select country'
        size='md'
        onChange={handleUserChange}
        value={selectedUser}
      >
        {users.map(user => (
          <option key={user._id} value={user._id}>
            {user.name}
          </option>
        ))}



</Select>



    <Flex 
    mt="10px"
    gap="10px"
    width="100%"
    direction="row"
  
    overflow="hidden">


<TableContainer width="100%" overflow="hidden">
  <Table variant='simple' size="sm" borderColor={bordercolor}>
    <TableCaption></TableCaption>
    <Tbody>
      {/* Conditional rendering for each row */}
      <Tr borderColor={bordercolor}>
        <Td w='100px' borderColor={bordercolor}>
          <Text as='' color={textcolor}>name</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text as='b' color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.name || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>capital</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.capital || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>population</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.population || "data"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>

      


{/* Spacer First Row */}
      <Tr>
        <Td> </Td>
      </Tr>

      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>nationality</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.nationality || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>

      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>region</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.region || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>subregion</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.subregion || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>













    </Tbody>
  </Table>
</TableContainer>







<TableContainer  width="100%" overflow="hidden">
  <Table variant='simple' size="sm" borderColor={bordercolor}>
    <TableCaption></TableCaption>
    <Tbody>
      {/* Conditional rendering for each row */}
      <Tr borderColor={bordercolor}>
        <Td w='100px' borderColor={bordercolor}>
          <Text as='' color={textcolor}>code</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text as='i' color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.iso3 || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>phonecode</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.phone_code || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr> 
     
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>currency</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.currency || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>

{/* Spacer First Row */}
      <Tr>
        <Td> </Td>
      </Tr>
      
      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>curr. name </Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.currency_name || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>

      <Tr borderColor={bordercolor}>
        <Td borderColor={bordercolor}>
        <Text as='' color={textcolor}>symbol</Text>
        </Td>
        <Td borderColor={bordercolor}>
          <Text color={textcolor}>
            {selectedUser ? (
              users.find(user => user._id === selectedUser)?.currency_symbol || "data-m"
            ) : (
              ""
            )}
          </Text>
        </Td>
      </Tr>














    </Tbody>
  </Table>
</TableContainer>



  </Flex>






<div> 
<Box position='relative' padding='5'>
  <Divider />
  <AbsoluteCenter bg='gray.200' px='4'>
    economic
  </AbsoluteCenter>
</Box>
</div>



  <Flex 
    mt="10px"
    gap="10px"
    width="100%"
    direction="column"       
    overflow="hidden">
    

<Box 
gap="10px"
bg="green.100" borderRadius='5px' py='5px' px='5px'>
  <StatGroup   px='5px' textAlign="left">
    <Stat>
      <StatLabel>GDP</StatLabel>
      <StatNumber>
        <Text as='b' color={textcolor}>
          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.metric1 || "data-m"
          ) : (
            ""
          )}
        </Text>
      </StatNumber>
      <StatHelpText>
      <StatArrow type=          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.m1increase || "data-m"
          ) : (
            ""
          )} />
        <Text as='b' color={textcolor}>
          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.metric1P || "data-m"
          ) : (
            ""
          )}
        </Text>
      </StatHelpText>
    </Stat>


    <Stat  textAlign="right">
      <StatLabel>M 2</StatLabel>
      <StatNumber>
        <Text as='b' color={textcolor}>
          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.metric2 || "data-m"
          ) : (
            ""
          )}
        </Text>
      </StatNumber>
      <StatHelpText>
      <StatArrow type=          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.m2increase || "data-m"
          ) : (
            ""
          )} />
        <Text as='b' color={textcolor}>
          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.metric2P || "data-m"
          ) : (
            ""
          )}
        </Text>
      </StatHelpText>
    </Stat>





    <Stat  textAlign="right">
      <StatLabel>M 3</StatLabel>
      <StatNumber>
        <Text as='b' color={textcolor}>
          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.metric3 || "data-m"
          ) : (
            ""
          )}
        </Text>
      </StatNumber>
      <StatHelpText>
        <StatArrow type=          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.m3increase || "data-m"
          ) : (
            ""
          )} />
        <Text as='b' color={textcolor}>
          {selectedUser ? (
            users.find(user => user._id === selectedUser)?.metric3P || "data-m"
          ) : (
            ""
          )}
        </Text>
      </StatHelpText>
    </Stat>


  </StatGroup>



  
</Box>



<Box 
gap="10px"
bg="blue.100" borderRadius='5px' py='5px' px='5px'>




  
</Box>


<div> 
<Box position='relative' padding='5'>
  <Divider />
  <AbsoluteCenter bg='gray.200' px='4'>
    category
  </AbsoluteCenter>
</Box>
</div>


<Flex 
direction='row'
gap="10px"
bg="gray.200" borderRadius='5px' py='5px' px='5px'>


  <Spacer />

<Flex direction='column'> 
<Text as='b'>
   HDI
    </Text>
<CircularProgress 
value={selectedUser ? (
            users.find(user => user._id === selectedUser)?.hdi || "data-m"
          ) : (
            ""
          )}
size='60px'
thickness='12px' 
color='green.400'>
  <CircularProgressLabel>
    <Text as='b'>
    {selectedUser ? (
            users.find(user => user._id === selectedUser)?.hdi || "data-m"
          ) : (
            ""
          )}%
    </Text>
  </CircularProgressLabel>
</CircularProgress>
</Flex>

  <Spacer />

<Flex 
direction='row'
gap="10px"
h="60%"
bg="gray.400" 
borderRadius='2px' 
py='0px' 
px='5px'>  
</Flex>

  <Spacer />

<Flex direction='column'> 
<Text as='b'>
   GINI
    </Text>
<CircularProgress 
value={selectedUser ? (
            users.find(user => user._id === selectedUser)?.gini || "data-m"
          ) : (
            ""
          )}
size='60px'
thickness='12px' 
color='green.400'>
  <CircularProgressLabel>
    <Text as='b'>
    {selectedUser ? (
            users.find(user => user._id === selectedUser)?.gini || "data-m"
          ) : (
            ""
          )}%
    </Text>
  </CircularProgressLabel>
</CircularProgress>
</Flex>

  <Spacer />





  
</Flex>
</Flex>  






</Card>



</Flex>
  );
}
