// Chakra Imports
import { ChevronDownIcon } from '@chakra-ui/icons';
import logoWhite from "assets/img/layout/aerilogo1.png";
import { MdDashboard } from "react-icons/md";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'
import { 
	
	IoMdMenu




} from "react-icons/io";

import { 
	MdCheckCircleOutline,
	MdInsights, 
	MdOutlineDownload, 
	MdOutlinePeople, 
	MdOutlineHome,
	MdOutlineCompareArrows
} from "react-icons/md";



import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	Input
  } from '@chakra-ui/react'

import {
	Button,
	Flex,
	Icon,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Divider,
	Center,
	useColorModeValue,
	Spacer
} from '@chakra-ui/react';
// Custom Components

import PropTypes from 'prop-types';
import React from 'react';
// Assets
import routes from 'routes.js';
export default function HeaderLinks(props) {

  

		const { isOpen, onOpen, onClose } = useDisclosure()
		const btnRef = React.useRef()




	// Chakra Color Mode
	let menuBg = useColorModeValue('teal', 'navy.800');
	const menucolor = 'white'
	const textcolor = 'gray.800'
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.36)'
	);
	return (
		<Flex
			w={{ sm: '100%', md: '100%' }}
			flexDirection="column"
			bg={menuBg}
			p="0px"
			borderRadius="15px"
      >
			



<Flex  
 minWidth='max-content' 
 alignItems='center' 
 gap='10'
 mt='10px'
 mb='10px'
 
 >
  
<ChakraLink
  as={ReactRouterLink}
  to="./home"

><div



> 
    		<Flex
        h="50px"
        ml="20px"
        alignContent="center"
        alignItems="center"
        > 
        <Text
        
        fontSize='20px'
        fontColor='white'
        fontWeight='800'
        
        
        > 
        PrijsProfijt
        
        </Text>
        </Flex>
        




			
	</div>
	</ChakraLink>








	<Flex 
	direction='row'

	display={{ sm: "none", md: "flex", xl: "flex" }}	>
<Flex>  





{/* Home */}
<Menu>
	<ChakraLink
		as={ReactRouterLink}
		to="./home"
		onClick={onClose}
	>	
		<MenuButton 
		height='60px'
		w='100%'
		as={Button}
		style={{
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '15px',
  }}
 		bg={menucolor}
    color={textcolor}
		>  
			<Flex
      > 
 
        <Text
        fontSize='20px'
        fontWeight='900'
        as='b'
        > 
        Home
        </Text>
			</Flex>
		</MenuButton>
	</ChakraLink>
</Menu>
{/* Home */}


{/* Vergelijk */}
<Menu>
      <MenuButton 
      height='60px'
      w='100%'
      bg={menucolor}
      color={textcolor}
      as={Button} rightIcon={<ChevronDownIcon />}
      borderRadius="0px"
      borderColor="green.200"
      borderWidth="0px"
      >

      <Flex> 
        <Text
        fontSize='20px'
        fontWeight='900'
        as='b'
        >
        Vergelijk
           </Text>
      </Flex>
      </MenuButton>
  
      <MenuList  
      height='60px'


      borderRadius="0px"
      width={{ base: '100%', sm: '100%', md: '200%' }} // Adjust width based on screen size
    >


      <ChakraLink
        as={ReactRouterLink}
        to="./energievergelijken"
      onClick={onClose}
      >
        <MenuItem  
      width={{ base: '100%', sm: '200%', md: '70%' }} // Adjust width based on screen size
          height='100%'
          bg='white'
          borderRadius="0px"
        >
          <Text  as='b'> Energie </Text> 
        </MenuItem>
      </ChakraLink>

      <ChakraLink
        as={ReactRouterLink}
        to="./verzekeringvergelijken"
      onClick={onClose}
      >
        <MenuItem  
          w='100%'
          height='100%'
          bg='white'
          borderRadius="0px"
        >
          <Text  as='b'> Verzekeringen </Text> 
        </MenuItem>
      </ChakraLink>

      <ChakraLink
        as={ReactRouterLink}
        to="./hypotheekvergelijken"
      onClick={onClose}
      >
        <MenuItem  
          w='100%'
          height='100%'
          bg='white'
          borderRadius="0px"
        >
          <Text  as='b'>Hypotheken </Text> 
        </MenuItem>
      </ChakraLink>




      </MenuList> 
</Menu>
{/* Vergelijk */}

{/* TopDealsMenu */}

{/* TopDealsMenu */}


</Flex>  
</Flex>		


	<Flex
      position="absolute"
	  mr="10px" 
      right={0}
      justifyContent="flex-end"
      display={{ sm: "flex", xl: "none", md: "none",  }}
    >

<Button


 ref={btnRef} colorScheme='white' onClick={onOpen}>
<Icon
          as={IoMdMenu}
          color='black'
          my='auto'
          w='30px'
          h='30px'
          me='10px'
          _hover={{ cursor: "pointer" }}
        />



       
      </Button>
      <Drawer
	    size='md'
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>

		  
		  </DrawerHeader>
<DrawerBody
mt="80px"
>



<Flex
direction='column'

>  


{/* Home */}
<Menu>
	<ChakraLink
		as={ReactRouterLink}
		to="./home"
		onClick={onClose}
	>	
		<MenuButton 
		height='60px'
		w='100%'
		as={Button}
		style={{
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '15px',
  }}
 		bg={menucolor}
    color={textcolor}
		>  
			<Flex
      > 
 
        <Text
        fontSize='20px'
        fontWeight='900'
        as='b'
        > 
        Home
        </Text>
			</Flex>
		</MenuButton>
	</ChakraLink>
</Menu>
{/* Home */}


{/* Vergelijk */}
<Menu>
      <MenuButton 
      height='60px'
      w='100%'
      bg={menucolor}
      color={textcolor}
      as={Button} rightIcon={<ChevronDownIcon />}
      borderRadius="0px"
      borderColor="green.200"
      borderWidth="0px"
      >

      <Flex> 
        <Text
        fontSize='20px'
        fontWeight='900'
        as='b'
        >
        Vergelijk
           </Text>
      </Flex>
      </MenuButton>
  
      <MenuList  
      height='60px'


      borderRadius="0px"
      width={{ base: '100%', sm: '100%', md: '200%' }} // Adjust width based on screen size
    >


      <ChakraLink
        as={ReactRouterLink}
        to="./energievergelijken"
      onClick={onClose}
      >
        <MenuItem  
      width={{ base: '100%', sm: '200%', md: '70%' }} // Adjust width based on screen size
          height='100%'
          bg='white'
          borderRadius="0px"
        >
          <Text  as='b'> Energie </Text> 
        </MenuItem>
      </ChakraLink>

      <ChakraLink
        as={ReactRouterLink}
        to="./verzekeringvergelijken"
      onClick={onClose}
      >
        <MenuItem  
          w='100%'
          height='100%'
          bg='white'
          borderRadius="0px"
        >
          <Text  as='b'> Verzekeringen </Text> 
        </MenuItem>
      </ChakraLink>

      <ChakraLink
        as={ReactRouterLink}
        to="./hypotheekvergelijken"
      onClick={onClose}
      >
        <MenuItem  
          w='100%'
          height='100%'
          bg='white'
          borderRadius="0px"
        >
          <Text  as='b'>Hypotheken </Text> 
        </MenuItem>
      </ChakraLink>




      </MenuList> 
</Menu>
{/* Vergelijk */}


</Flex>  


</DrawerBody>

<Center>
<Divider thickness='10px' w='70%'/>
</Center>

		  <DrawerFooter display="flex" justifyContent="center">



  
  <Image src='https://wilsonclinic.com/wp-content/uploads/2018/12/placeholder-logo-2.png' boxSize="80px" objectFit="contain" />
  <Text  as='sub'> analysis </Text> 



</DrawerFooter>

        </DrawerContent>
      </Drawer>



    </Flex>


</Flex>

			







		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func,
	onClose: PropTypes.func.isRequired, // Ensure o
};
