import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'
import { MdLineAxis,  MdCheckCircleOutline } from "react-icons/md";
import LogoIMG from "assets/img/layout/aerilogo3.png";
import { MdCheck,  MdCallSplit, MdOutlineCompareArrows } from "react-icons/md";



import { 
  Flex,
  Icon, 
  Box, 
  Text, 
  Input, 
  Button,
  Stack,
  InputRightElement,
  Image,
  Center,
Square,
Accordion,
AccordionItem,
AccordionButton,
AccordionIcon,
AccordionPanel,




} from "@chakra-ui/react";

import { EmailIcon , CheckIcon,  } from '@chakra-ui/icons'


// const response = await axios.post("http://localhost:8080/saveAnswers", answers);
// const response = await axios.post("https://aeri-api.onrender.com/saveAnswers", answers);


// Fetch data from the server endpoint LINK: https://aeri-api.onrender.com/getCountry

const submitAnswers = async (answers) => {
  try {
    const response = await axios.post("https://aeri-api.onrender.com/saveAnswers", answers);
    console.log("Answers saved:", response.data);
    // Handle success, e.g., show a success message to the user
  } catch (error) {
    console.error("Error saving answers:", error);
    // Handle error, e.g., show an error message to the user
  }
};





export default function Banner(props) {
  const [users, setUsers] = useState([]);
  const handleSubmit = () => {
    // Call submitAnswers function when the user clicks the submit button
    submitAnswers(answers);
    // For demonstration purposes, set answersSubmitted to true
    setAnswersSubmitted(true);



    const text1 = "gray.400";





  };

  useEffect(() => {
    // Fetch data from the http://localhost/8080/getCountry'
  
  // Fetch data from the server endpoint LINK: https://aeri-api.onrender.com/getCountry'
  axios.get('http://localhost/8080/getCountry')
  .then(response => {
    // Update the state with the fetched data
    setUsers(response.data);
  })
  .catch(error => {
    console.error('Error fetching users:', error);
  });
  }, []); // Empty dependency array to run only once when the component mounts
  
  


  const [answers, setAnswers] = useState({}); // State to store answers
  const [answersSubmitted, setAnswersSubmitted] = useState(false); // State to track if answers are submitted

  // Function to handle input change
  const handleInputChange = (question, value) => {
    setAnswers({ ...answers, [question]: value });
  };


  return (
<Flex
    mt="10px"
      pe="0px"
      mr="0px"
      px="10px"
      bg="green.400"
      borderRadius="15px"
>

    <Flex
      mr="0px"
      mt="-2px"
      pe="0px"
      px="0px"
      bg=""
      width="100%"
      maxHeight="600px"
      minHeight="300px"
      borderRadius="15px"
      direction="column"
      p={0} // Added padding to the Flex container
      justifyContent='center'

    >






<Flex 
width="100%"
height="100%"
 alignItems='center'
 mt="0px"
 px="5px"
 borderRadius="15px"


bg='white'
flex='1' 
>

{/* LeftBox */}
<Flex 
width="50%"
borderTopLeftRadius="15px"
borderBottomLeftRadius="15px"
bg='#090a1c' 
height="100%"
alignItems='center'
>
  <Text 
    width="100%"
    mt='0px'
    mb='0px'
    ml='0px'
    mr='0px'
    textAlign='center'
    fontWeight='800'
    fontSize='18px'
    color='gray.200'
  >

  <Flex
            alignItems='center'
          justifyContent="center"
          direction='column'>


 <Text
 fontSize={{ base: "2xl", md: "2xl", xl: "3xl", sm: "md" }}
 >    
PrijsProfijt...  
</Text>
             
</Flex>
             
             </Text>
  </Flex>



{/* RightBox */}
  <Flex
borderTopRightRadius="15px"
borderBottomRightRadius="15px"
width="50%"
height="100%"
 alignItems='center'
 mt="0px"
 px={{ base: "20px", md: "20px", sm: "0px" }}
 h=""
color=''
size='100%'
bg='inherit'
  
flex='1' >  


{/* Accordion Text */}
<Accordion  
width='300px'
maxWidth='250px'
allowToggle
borderColor='gray.300'

>
  <AccordionItem>
    <h2>
      <AccordionButton
     
      
      >
        <Box as='span' flex='1' textAlign='left'
        fontSize={{ base: "xs", md: "lg", xl: "xl", sm: "xs" }}
        fontWeight='bold'
        alignItems='center'
        fontColor='green'
        >
        <Flex
          alignItems='center'
        >  
<Icon as={MdCheck} fontSize='21px' mr="5px" color="green.500" />
        Scherpe prijzen
</Flex>


        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton
     
      
      >
        <Box as='span' flex='1' textAlign='left'
        fontSize={{ base: "xs", md: "lg", xl: "xl", sm: "xs" }}
        fontWeight='bold'
        alignItems='center'
        
        >
        <Flex
          alignItems='center'
        >  
<Icon as={MdCheck} fontSize='21px' mr="5px" color="green.500" />
      Onafhankelijk advies
</Flex>


        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton
     
      
      >
        <Box as='span' flex='1' textAlign='left'
        fontSize={{ base: "xs", md: "lg", xl: "xl", sm: "xs" }}
        fontWeight='bold'
        alignItems='center'
        
        >
        <Flex
          alignItems='center'
        >  
<Icon as={MdCheck} fontSize='21px' mr="5px" color="green.500" />
       Makkelijk overstappen
</Flex>


        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton
     
      
      >
        <Box as='span' flex='1' textAlign='left'
        fontSize={{ base: "xs", md: "lg", xl: "xl", sm: "xs" }}
        fontWeight='bold'
        alignItems='center'
        
        >
        <Flex
          alignItems='center'
        >  
<Icon as={MdCheck} fontSize='21px' mr="5px" color="green.500" />
        Alles op één plek
</Flex>


        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </AccordionPanel>
  </AccordionItem>

</Accordion>




  </Flex>



</Flex>







    </Flex>


    </Flex>

  );
}
