import React from "react";

// chakra imports
import {
  Box,
  Flex,
  Text,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import AdminNavbarLinks2 from 'components/navbar/NavbarLinksAdmin2';

import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

// Assets
import { IoMenuOutline, IoMenuSharp } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";

function Sidebar(props) {
  const { routes } = props;

  // Define paths to filter out
  const pathsToFilter = ["/dashboardA"];

  // Filter routes to exclude pathsToFilter
  const filteredRoutes = routes.filter(route => !pathsToFilter.includes(route.path));

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );

  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  // SIDEBAR
  return (
    <Box display={{ sm: "none", xl: "none" }} w="100%" position='fixed' minH='100%'>
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w='200px'
        h='100vh'
        m={sidebarMargins}
        minH='100%'
        overflowX='hidden'
        boxShadow={shadow}>
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}>
          <Content routes={filteredRoutes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}


// FUNCTIONS
export function SidebarResponsive(props) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;

  // Define paths to filter out
  const pathsToFilter = ["/dashboardA"];

  // Filter routes to exclude pathsToFilter
  const filteredRoutes = routes.filter(route => !pathsToFilter.includes(route.path));

  return (
    <Flex alignItems='flex-start' display={{ sm: "flex", xl: "none" }}>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="top"
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            zIndex='3'
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW='100%' px='0rem' pb='0'>
            <AdminNavbarLinks2 closeDrawer={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
        <Icon
          as={IoMdMenu}
          color='black'
          my='auto'
          w='30px'
          h='30px'
          me='10px'
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
    </Flex>
  );
}


// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
