// Chakra imports
import { Flex, Skeleton, } from "@chakra-ui/react";
import React, { useEffect } from "react";

export default function Banner(props) {

  useEffect(() => {
    // Load the script when the component mounts
    const script = document.createElement("script");
    script.src = "https://daisycon.tools/health-insurance/app.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Chakra Color Mode

  return (  

      <Flex 
      mb='10px'
      mt='10px'
      pe='0px'
      bg='#090a1c'
      width='100%'
      alignContent='center'
      alignItems='center'

      h="100%"
      borderRadius='5px'
      direction='column'
      >   

{/* Main Block Inside Card - COLOR*/}




<Flex
      mb='10px'
      mt='0px'
> 
<div class="dc-tool dc-health-insurance-tool" data-config='{"mediaId":{"daisycon":399243,"xpartners":null},"subId":{"daisycon":"","xpartners":null},"showFilters":true,"filter":{"alternativeHealthcare":{"value":null},"birthControl":{"value":null},"choiceInHealthcare":{"value":null},"deductibleExcess":{"value":null},"dentalInsuranceAmount":{"value":null},"dentalInsurancePercentage":{"value":null},"eyeCare":{"value":null},"medications":{"value":null},"orthodontics":{"value":null},"physiotherapy":{"value":null},"pregnancyChildbirth":{"value":null},"providerId":{"value":[]},"vaccinations":{"value":null}},"language":"nl","limit":12,"colorPrimary":"#FFFFFF","colorSecondary":"#008080"}'></div>
</Flex>




      </Flex> 
  );
}
