import React from 'react';
import { Flex, Box, Icon, Text, Button, Accordion, List, ListItem, ListIcon, AccordionItem, AccordionPanel, AccordionIcon, AccordionButton, useColorModeValue } from "@chakra-ui/react";
import { MdLineAxis, MdCheckCircle, MdSettings, MdDashboard, MdCarRental, MdAnalytics, MdInfo, MdAreaChart, MdTableChart, Md10K } from "react-icons/md";
import Card from "components/card/Card.js";
import { IoMdAnalytics, IoMdInformation } from 'react-icons/io';






const ChartSidebar = ({ handleChartChange }) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  return (
    <Card borderRadius='0px' w='200px'  mb={{ base: "0px", sm: "0px", "2xl": "0px" }}>














<Accordion defaultIndex={[0]} allowMultiple allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
      <Box as='span' flex='1' textAlign='left'>      
      

        <Flex justifyContent="center">    
          <Text fontSize={14}> categorie </Text>  
            <Button colorScheme='gray' size='xs' ml='5px' borderRadius='sm' onClick={() => handleChartChange('A')}>
                <Icon as={MdLineAxis} width='12px' mr='0px' height='12px' color='inherit' /> 
            </Button>
        </Flex>


        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>



    <AccordionPanel pb={0}>


    <List spacing={3}>
      <ListItem display="flex" justifyContent="space-between" alignItems="center">
          <div>
            <ListIcon as={MdCheckCircle} width='12px' mb='3px' height='12px' color='green.500' />
            Item
          </div>
          <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('B')}>
            <Icon as={IoMdAnalytics} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
          </Button>
          <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('B')}>
            <Icon as={IoMdInformation} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
          </Button>
      </ListItem>


      <ListItem display="flex"  mb='5px' justifyContent="space-between" alignItems="center">
        <div>
          <ListIcon as={MdCheckCircle} width='12px' mb='3px' height='12px' color='green.500' />
          Item
        </div>
        <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('C')}>
          <Icon as={MdTableChart} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
        </Button>
        <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('B')}>
          <Icon as={IoMdInformation} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
        </Button>
      </ListItem>

    </List>

       
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
      <Box as='span' flex='1' textAlign='left'>      
      

        <Flex justifyContent="center">    
          <Text fontSize={14}> categorie </Text>  
            <Button colorScheme='gray' size='xs' ml='5px' borderRadius='sm' onClick={() => handleChartChange('A')}>
                <Icon as={MdLineAxis} width='12px' mr='0px' height='12px' color='inherit' /> 
            </Button>
        </Flex>


        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>



    <AccordionPanel pb={0}>


    <List spacing={3}>
      <ListItem display="flex" justifyContent="space-between" alignItems="center">
          <div>
            <ListIcon as={MdCheckCircle} width='12px' mb='3px' height='12px' color='green.500' />
            Item
          </div>
          <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('B')}>
            <Icon as={MdAreaChart} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
          </Button>
          <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('B')}>
            <Icon as={IoMdInformation} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
          </Button>
      </ListItem>


      <ListItem display="flex"  mb='5px' justifyContent="space-between" alignItems="center">
        <div>
          <ListIcon as={MdCheckCircle} width='12px' mb='3px' height='12px' color='green.500' />
          Item
        </div>
        <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('C')}>
          <Icon as={MdTableChart} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
        </Button>
        <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('B')}>
          <Icon as={IoMdInformation} width='15px' ml='0px' alignSelf='center' height='15px' color='inherit' />
        </Button>
      </ListItem>

    </List>

       
    </AccordionPanel>
  </AccordionItem>



</Accordion>








        <Flex justifyContent="center">
          <Box bg='white' h='100px' w='150px' p={3} color='black' borderRadius='md'>
            <div style={{ display: 'flex',  marginTop: '30px', gap: '10px', justifyContent: 'center' }}>
              <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('A')}>
                <Icon as={MdLineAxis} width='15px' ml='0px' height='20px' color='inherit' />A
              </Button>
              <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('B')}>
                <Icon as={MdDashboard} width='15px' ml='0px' height='20px' color='inherit' />B
              </Button>
              <Button colorScheme='gray' size='xs' borderRadius='sm' onClick={() => handleChartChange('C')}>
                <Icon as={MdCarRental} width='15px' ml='0px' height='20px' color='inherit' />C
              </Button>
            </div>
          </Box>
        </Flex>
    
    </Card>
  );
}

export default ChartSidebar;
