// Chakra imports
import { Spacer, Image, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import General from "views/admin/profile/components/General";



export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    
    
    
    
    
    
      <Flex>        
        <Image
        boxSize='75px'
        objectFit='contain'
        src='https://cdn.pixabay.com/photo/2023/06/01/12/02/excel-logo-8033473_960_720.png'>          
        </Image>
        
 
        <Spacer />

        <Image
        boxSize='75px'
        objectFit='contain'
        src='https://logos-world.net/wp-content/uploads/2021/10/Tableau-Emblem.png'>          
        </Image>

        <Spacer />

        <Image
        boxSize='75px'
        objectFit='contain'
        src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Sql_data_base_with_logo.png/640px-Sql_data_base_with_logo.png'>          
        </Image>

        <Spacer />

        <Image
        boxSize='75px'
        objectFit='contain'
        src='https://www.ncs-online.com/wp-content/uploads/2023/10/R-2.png'>          
        </Image>

      </Flex>
 
  );
}
