// Chakra imports
import React, { useState, useEffect } from "react";
import axios from "axios";


import {  useColorModeValue, Button } from "@chakra-ui/react";
import Project from "views/admin/energiedash/components/maindashlist"
import GeneralInformation from "views/admin/energiedash/components/chart3"

export default function Projects({ selectedUser, setSelectedUser }) {
  const [users, setUsers] = useState([]);

  const handleUserChange = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);
  };



  useEffect(() => {
    axios.get('http://localhost:8080/getRegion')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);




  const handleEdit = (projectId) => {
    // Implement your edit logic here, like opening a modal or navigating to an edit page
    console.log("Editing project with ID:", projectId);
  };




  return (


      <Project 
      
        tableRow1={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow1 ||"data-m") : ("")}
        tableRow1A={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow1A ||"data-m") : ("")}
        tableRow2={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow2 ||"data-m") : ("")}
        tableRow2A={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow2A ||"data-m") : ("")}
        tableRow3={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow3 ||"data-m") : ("")}
        tableRow3A={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow3A ||"data-m") : ("")}    
        
        tableRow1B={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow1B ||"data-m") : ("")}
        tableRow1AB={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow1AB ||"data-m") : ("")}
        tableRow2B={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow2B ||"data-m") : ("")}
        tableRow2AB={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow2AB ||"data-m") : ("")}
        tableRow3B={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow3B ||"data-m") : ("")}
        tableRow3AB={selectedUser ? (users.find(user => user._id === selectedUser)?.tableRow3AB ||"data-m") : ("")}    
        
        
        link={selectedUser ? (users.find(user => user._id === selectedUser)?.link ||"data-m") : ("")}
        chart={selectedUser ? (users.find(user => user._id === selectedUser)?.chartMDA ||"data-m") : ("")}
        chart2={selectedUser ? (users.find(user => user._id === selectedUser)?.chartMDB ||"data-m") : ("")}
        title={selectedUser ? (users.find(user => user._id === selectedUser)?.dashboardtitle ||"data-m") : ("")}
        titleMD={selectedUser ? (users.find(user => user._id === selectedUser)?.maindashboardtitle ||"data-m") : ("")}
        MDbgimage={selectedUser ? (users.find(user => user._id === selectedUser)?.maindashboardimage ||"data-m") : ("")}
        tag1=''
        tag2=''
        tag3=''


        textinfo=''
        CirPro1={selectedUser ? (users.find(user => user._id === selectedUser)?.MDnumber ||"data-m") : ("")}
        CirProTag1={selectedUser ? (users.find(user => user._id === selectedUser)?.MDbadge ||"data-m") : ("")}
        CirProTag2={selectedUser ? (users.find(user => user._id === selectedUser)?.MDtext ||"data-m") : ("")}
        CirProTag3={selectedUser ? (users.find(user => user._id === selectedUser)?.MDinfo ||"data-m") : ("")}
        CirProTag4={selectedUser ? (users.find(user => user._id === selectedUser)?.MDtext ||"data-m") : ("")}

        DashPar0={selectedUser ? (users.find(user => user._id === selectedUser)?.MDnumberB ||"data-m") : ("")}
        DashPar1={selectedUser ? (users.find(user => user._id === selectedUser)?.MDinfoB ||"data-m") : ("")}
        DashPar2={selectedUser ? (users.find(user => user._id === selectedUser)?.MDbadgeB ||"data-m") : ("")}
        DashPar3={selectedUser ? (users.find(user => user._id === selectedUser)?.MDtextB ||"data-m") : ("")}
        DashPar4={selectedUser ? (users.find(user => user._id === selectedUser)?.chart ||"data-m") : ("")}

        DashPar5={selectedUser ? (users.find(user => user._id === selectedUser)?.MDnumberB ||"data-m") : ("")}
        DashPar5A={selectedUser ? (users.find(user => user._id === selectedUser)?.MDnumberPB1 ||"data-m") : ("")}
        DashPar6A={selectedUser ? (users.find(user => user._id === selectedUser)?.MDnumberPB2 ||"data-m") : ("")}
        DashPar6={selectedUser ? (users.find(user => user._id === selectedUser)?.MDbadgeB ||"data-m") : ("")}
        DashPar7={selectedUser ? (users.find(user => user._id === selectedUser)?.chart ||"data-m") : ("")}
        DashPar7A={selectedUser ? (users.find(user => user._id === selectedUser)?.chart ||"data-m") : ("")}

        DashPar0B={selectedUser ? (users.find(user => user._id === selectedUser)?.MDnumberC ||"data-m") : ("")}
        DashPar1B={selectedUser ? (users.find(user => user._id === selectedUser)?.MDinfoC ||"data-m") : ("")}
        DashPar2B={selectedUser ? (users.find(user => user._id === selectedUser)?.MDbadgeC ||"data-m") : ("")}
        DashPar3B={selectedUser ? (users.find(user => user._id === selectedUser)?.MDtextC ||"data-m") : ("")}
        DashPar4B={selectedUser ? (users.find(user => user._id === selectedUser)?.chart ||"data-m") : ("")}

        DashPar0C={selectedUser ? (users.find(user => user._id === selectedUser)?.MDnumberD ||"data-m") : ("")}
        DashPar1C={selectedUser ? (users.find(user => user._id === selectedUser)?.MDinfoD ||"data-m") : ("")}
        DashPar2C={selectedUser ? (users.find(user => user._id === selectedUser)?.MDbadgeD ||"data-m") : ("")}
        DashPar3C={selectedUser ? (users.find(user => user._id === selectedUser)?.MDtextD ||"data-m") : ("")}
        DashPar4C={selectedUser ? (users.find(user => user._id === selectedUser)?.charts ||"data-m") : ("")}

      >
        <Button onClick={() => handleEdit("A")} variant="outline" size="sm" colorScheme="blue">Edit</Button>
      </Project>





      
   
  );
}



