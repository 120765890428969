import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdHome, MdLineAxis, MdDashboard, MdPeople, MdPerson, MdDownload,
  MdRadioButtonChecked,
} from "react-icons/md";

// Admin Imports
import Contact from "views/admin/contact";
import Profile from "views/admin/profile";
import Dashboardx from "views/admin/dashboard";
import Match from "views/admin/match";
import Overons from "views/admin/overons/pageA";
import Analyse from "views/admin/analyse";
import Dashboard from "views/admin/dashboard";
import DashEnergie from "views/admin/energiedash";
import DashEurope from "views/admin/energiedash/dash-europe";
import DashAsia from "views/admin/energiedash/dash-asia";
import Energiecomp from "views/admin/dashboard/components/energiecomp"
import Energievergelijker from "views/admin/dashboard/energievergelijker"
import Hypotheekcomp from "views/admin/dashboard/hypotheekcomp"
import Verzekeringcomp from "views/admin/dashboard/verzekeringcomp"



// Auth Imports

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "/home",   
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Profile,
    secondary: false,
  },




{
  name: "Dashboards",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/dashboard",
  component: Dashboard,

},

{
  name: "A",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/dashboardA",
  component: DashEnergie,

},


{
  name: "B",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/dashboardB",
  component: DashEurope,

},


{
  name: "B",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/dashboardC",
  component: DashAsia,

},


{
  name: "B",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/dashboardDev",
  component: Overons,

},
{
  name: "Energiepage",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/energievergelijken",
  component: Energiecomp,

},
{
  name: "Energievergelijker DC",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/energievergelijker",
  component: Energievergelijker,

},

{
  name: "Hypotheek",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/hypotheekvergelijken",
  component: Hypotheekcomp,

},

{
  name: "Verzekering",
  icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
  layout: "/admin",
  path: "/verzekeringvergelijken",
  component: Verzekeringcomp,

},
  {
    name: "Downloads",
    layout: "/admin",
    path: "/downloads",
    icon: <Icon as={MdDownload} width='20px' height='20px' color='inherit' />,
    component: Analyse,
  },
  {
    name: "Contact",
    layout: "/admin",
    path: "/dashboardDev",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Overons,
  },
];

export default routes;
