import React, { useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Icon,
  Button,
  Flex,
  SimpleGrid,
  Image,

} from "@chakra-ui/react";
import {
  MdLineAxis, MdDashboard,
} from "react-icons/md";

// Custom components
import logoWhite from "assets/img/layout/aerilogo1.png";
import { IoMdPlanet } from "react-icons/io";
import Card from "components/card/Card";
import Chart from "views/admin/analyse/components/chart";
import Chart2 from "views/admin/analyse/components/chart2";
import Chart3 from "views/admin/analyse/components/chart3";
import ChartSidebar from "views/admin/analyse/components/chartsidebar";
import ChartInfo from "views/admin/analyse/components/chartinfo";

const Index = () => {
  const [activeComponent, setActiveComponent] = useState('A');

  // Define list of possible components
  const components = [
    { id: 'A', component: <Chart /> },
    { id: 'B', component: <Chart2 /> },
    { id: 'C', component: <Chart3 /> },
    // Add more components as needed
  ];

    // Define list of possible components
    const components1 = [
      { id: 'A', component: <ChartInfo /> },
      { id: 'B', component: <ChartInfo /> },
      { id: 'C', component: <ChartInfo /> },
      // Add more components as needed
    ];

  // Function to handle component selection
  const handleButtonClick = (componentId) => {
    setActiveComponent(componentId);
  };

  return (
    <Box pt={{ base: "15px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="10px" mb="10px">
        <Box colSpan={{ base: 1, md: 2 }}>
          <Card direction="column" w="100%" h="100%" px="0px" borderRadius="5px">
            <Grid h='100%' templateRows='repeat(3, 1fr)' templateColumns='repeat(5, 1fr)' gap={1}>



              <GridItem width='100%' rowSpan={2} colSpan={4} bg='white'>
                <Flex height='300px' direction="column">
                  {/* Render selected component */}
                  {components.find(comp => comp.id === activeComponent)?.component}

                </Flex> 
              </GridItem>


                  <GridItem height='100%' width='100%' rowSpan={4} colSpan={1} bg='white'>
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                      <ChartSidebar handleChartChange={handleButtonClick} />
                      <Flex alignItems="center" justifyContent="center">
                        <Image src={logoWhite} boxSize="50px" objectFit="contain" />
                      </Flex>
                      <Box textAlign="center">
                        
                      </Box>
                    </Box>
                  </GridItem>



              <GridItem width='100%' height='100%' colSpan={4} bg='white' > 
                {/* Content for the remaining grid item */}
                <Flex  direction="column">
                  {/* Render selected component */}
                  {components1.find(comp => comp.id === activeComponent)?.component}
                  
                </Flex> 

               

              </GridItem>              
            </Grid>

            <Flex px="0px" gap='30px' justify="space-evenly" mt="50px" align="center" justifyContent="center">
                    <div>
                      {/* Render buttons to select components */}
                      {components.map((component) => (
                        <Button
                          key={component.id}
                          onClick={() => handleButtonClick(component.id)}
                          colorScheme='gray'
                          size='xs'
                          borderRadius='sm'
                        >          
                          {/* Render component icon */}
                          {component.id === 'A' && <Icon as={MdLineAxis} width='15px' ml='10px' height='20px' color='inherit' />}
                          {component.id === 'B' && <Icon as={MdDashboard} width='15px' ml='10px' height='20px' color='inherit' />}
                          {component.id === 'C' && <Icon as={IoMdPlanet} width='15px' ml='10px' height='20px' color='inherit' />}
                          {/* Add more icons as needed */}
                        </Button>
                      ))}
                    </div>           
                  </Flex>

          </Card>
        </Box>
      </SimpleGrid>

      
    </Box>
  );
};

export default Index;
