// Chakra imports
import { 
  Spacer,
   Image, 
   Button,
   Stack,
   Box, 
   Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import General from "views/admin/profile/components/General";

import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'


import { EmailIcon , ArrowForwardIcon, WarningIcon } from '@chakra-ui/icons'







export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    
    
<Flex 
  mb='0px'
  mt='10px'
  pe='-20px'
  bg='teal'
  width='100%'
  alignItems='center'
  justifyItems='center'
  borderRadius='15px'
  justifyContent='center' // Center the content horizontally

> 
   

    
<Flex 
  mb='10px'
  mt='0px'
  pe='0px'
  bg='#090a1c'
  width='100%'
  alignItems='center'
  justifyItems='center'
  borderRadius='15px'
  borderColor='white'
  borderWidth='10px'
  justifyContent='center' // Center the content horizontally
  direction='row'
>   
  <Flex
    bg='inherit'
    mt='60px'
    width='60%'
    alignItems='center'
    direction='column'
  > 
    <Text 
      textAlign='center'
      fontWeight='500'
      color='gray.200'
      fontSize='18px'
    >

      "Ontdek de <b><i>beste deals</i></b> voor energie, verzekeringen en hypotheek met onze handige vergelijkingstool.
      

     
      
    
      
    </Text>

    <Stack
      mt='20px'
      mb='20px'
      direction='column'
      spacing={0}
    >
      <ChakraLink as={ReactRouterLink} to="">
        <Button 
          size='lg'
          borderRadius='10px'
          rightIcon={<ArrowForwardIcon />}  
          bg='' 
          colorScheme='green' 
          variant='outline'
        >
          Start
        </Button>
      </ChakraLink>
    </Stack>
  </Flex>
</Flex>

</Flex>








 
  );
}
