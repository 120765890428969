// Chakra imports
import { Text, Flex, useColorModeValue, Button } from "@chakra-ui/react";
// Assets



// Custom components

import { Icon } from "@chakra-ui/react";


import logoWhite from "assets/img/layout/aerilogo1.png";


import Card from "components/card/Card.js";




import React from "react";
import Project from "views/admin/energiedash/components/maindashlistEU";
import GeneralInformation from "views/admin/energiedash/components/chart3";

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");


  const handleEdit = (projectId) => {
    // Implement your edit logic here, like opening a modal or navigating to an edit page
    console.log("Editing project with ID:", projectId);
  };

  return (


      <Project 
      
        link='' // Adjust the URL path as needed
        chart='3'
        chart2='3B'
        title='Regional Stats - Europe'
        tag1='baananbod'
        tag2='jobs'
        tag3='werkgelegenheid'
        textinfo=''
        CirPro1='100'
        CirProTag1='Population'
        CirProTag2=''
        CirProTag3='500 million'
        CirProTag4=''

        DashPar0='75'
        DashPar1='800 million'
        DashPar2='Employment'
        DashPar3=''
        DashPar4=''

        DashPar5='Topic A'
        DashPar5A='100'
        DashPar6='Topic B'
        DashPar6A='100'
        DashPar7='Topic C'
        DashPar7A='65'

        DashPar0B='100'
        DashPar1B='€ 400 billion '
        DashPar2B='Average GDP'
        DashPar3B=''
        DashPar4B='WW uitkering'

        DashPar0C='2'
        DashPar1C='2%'
        DashPar2C='AVerage GDP Growth'
        DashPar3C=''
        DashPar4C='Totaal verzuim'

      >
        <Button onClick={() => handleEdit("A")} variant="outline" size="sm" colorScheme="blue">Edit</Button>
      </Project>





      
   
  );
}



