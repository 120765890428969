import { Icon } from "@chakra-ui/react";
import { MdLineAxis, MdDashboard, MdTableRows, MdTabUnselected, MdTableChart, MdInfo } from "react-icons/md";
import { Button, Tabs,
  Stack,
  Divider,
  AbsoluteCenter,
  Badge,
  Center,
  SimpleGrid,
  Spacer,
  TabList,
  Tab,
  Grid,
  Progress,
  CircularProgress,
  CircularProgressLabel,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  GridItem,
  Tooltip,
  TabPanels,
  TabPanel, } from "@chakra-ui/react";
import Chart from "views/admin/analyse/components/chart";
import Chart2 from "views/admin/analyse/components/chart2";
import Chart3 from "views/admin/energiedash/components/chart3";
import Chart3B from "views/admin/energiedash/components/chart3B";

import ChartA1 from "views/admin/analyse/components/chartA1";

import LogoIMG from "assets/img/layout/aerilogo1.png";



import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Project(props) {
  const { 
  DashPar0,
  DashPar1,
  DashPar2,
  DashPar3,
  DashPar4,
  DashPar5,
  DashPar6,
  DashPar7,
  DashPar5A,
  DashPar6A,
  DashPar7A,
  DashPar1B,
  DashPar2B,
  DashPar3B,
  DashPar4B,
  DashPar1C,
  DashPar2C,
  DashPar3C,
  DashPar4C,
  DashPar0B,
  DashPar0C,
  title,
  link, 
  ink2, 
  chart, 
  chart2, 
  textinfo, 
  CirPro1, 
  CirProTag1, 
  CirProTag2, 
  CirProTag3, 
  CirProTag4, 
  tag1, tag2, 
  tag3, ...rest } = props;
  const cardcolor = "blue.800";
  const txtc = "gray.200";
  const txtc2 = "gray.400";
  const bdgc1 = "green";
  const bdgc2 = "blue";
  const bdgc3 = "gray";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");

  let chartComponent2;
  switch (chart2) {
    case "1":
      chartComponent2 = <Chart />;
      break;
    case "2":
      chartComponent2 = <Chart2 />;
      break;
    // Add more cases for additional chart types if needed
    case "3":
      chartComponent2 = <Chart3 />;
      break;
    case "3B":
      chartComponent2 = <Chart3B />;
      break;
    case "A1":
        chartComponent2 = <ChartA1 />;
        break;

       
      
      



    default:
      chartComponent = null;
  }

  let chartComponent;
  switch (chart) {
    case "1":
      chartComponent = <Chart />;
      break;
    case "2":
      chartComponent = <Chart2 />;
      break;
    // Add more cases for additional chart types if needed
    case "3":
      chartComponent = <Chart3 />;
      break;
    case "A1":
        chartComponent = <ChartA1 />;
        break;

       
      
      



    default:
      chartComponent = null;
  }

  return (
    <Card
      h="100%"
      borderRadius="5px"
      backgroundColor={cardcolor}
    >

        <Flex  direction='column' mt={{ base: "0px", md: "0px", sm: "0px" }}>
          
          <Flex 
          alignItems='center'
          justifyContent="center"
          direction='column'>
          

            <Text
              color='white'
              fontWeight="800"
              fontSize="20px"
              textAlign="center"
              mb="0px"
            >
             {title} 
            </Text>

          </Flex>


     
    <Flex 
   direction='column'
    bg={cardcolor}
      
      
      >




        <Flex 
        gap='15px' 
        py='15px'    >


        <Flex  w='100%' gap='10px' alignItems='center' justifyContent="flex-end">  
  <Flex textAlign="right" direction='column'> 
    <Badge fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>
      {CirProTag1}
    </Badge>
    <Stat mt='5px' color='white' >
      <StatNumber  color={txtc} fontSize='18px' >
        {CirProTag3}
        <Tooltip label={CirProTag4} aria-label='A tooltip'>
          <StatLabel><Text color={txtc2} as='b'>{CirProTag2}</Text></StatLabel>
        </Tooltip>
      </StatNumber>
    </Stat>
  </Flex>
  <CircularProgress  colorScheme='white' thickness='10px' color='green.300' value={CirPro1} size='65px' >
    <CircularProgressLabel  color={txtc}><Text as='b'>{CirPro1}%</Text></CircularProgressLabel>
  </CircularProgress>
</Flex>


          
<Center height='100px'>
  <Divider orientation='vertical' />
</Center>         
      


          <Flex    w='100%' gap='10px'  direction='row' alignItems='center'>
          
            <CircularProgress  thickness='10px' color='green.400' value={DashPar0} size='65px' >
                <CircularProgressLabel  color={txtc}><Text as='b'>{DashPar0}%</Text></CircularProgressLabel>
            </CircularProgress>
            


        <Flex textAlign="left" direction='column'> 

        <Badge w='100%' fontSize='12px' borderRadius='5px' colorScheme={bdgc1} color='gray.800'>

        {DashPar2}</Badge>

            <Stat mt='5px' color='white' >
            <StatNumber  color={txtc} fontSize='18px' >

            {DashPar1}

            <Tooltip label={DashPar4} aria-label='A tooltip'>


            
            <StatLabel><Text color={txtc2} as='b'>{DashPar3}</Text></StatLabel>
        </Tooltip>



            </StatNumber>



          </Stat>
        </Flex>



          </Flex>
      
         </Flex>





      
      <Flex 
      gap='15px' 
        py='15px'    >
      
      
      
      
      <Flex w='100%' gap='10px' alignItems='center' justifyContent="flex-end">
  

        <Flex textAlign="right" direction='column'> 
        <Badge fontSize='12px' borderRadius='5px' colorScheme={bdgc2} color='gray.800'>

        {DashPar2B}</Badge>
            <Stat color={txtc}>
            <StatNumber  mt='5px' fontSize='18px' >
            {DashPar1B}
            <Tooltip label={DashPar4B} aria-label='A tooltip'>


            
            <StatLabel ><Text color={txtc2} as='b'>{DashPar3B}</Text></StatLabel>
        </Tooltip>



            </StatNumber>



          </Stat>
        </Flex>
        <CircularProgress thickness='10px' color='blue.400' value={DashPar0B} size='65px' >
              <CircularProgressLabel  color={txtc}><Text as='b'>{DashPar0B}%</Text></CircularProgressLabel>
          </CircularProgress>  


      </Flex>


      <Center height='70px'>
  <Divider orientation='vertical' />
</Center>   



      <Flex  w='100%' gap='10px' bg='' direction='row' alignItems='center'>
      
        <CircularProgress thickness='10px' color='green.400' value={DashPar0C} size='65px' >
            <CircularProgressLabel  color={txtc}><Text as='b'>{DashPar0C}%</Text></CircularProgressLabel>
        </CircularProgress>
        


    <Flex textAlign="left" direction='column'> 

    <Badge w='100%' fontSize='12px' borderRadius='5px' colorScheme={bdgc2} color='gray.800'>

    {DashPar2C}</Badge>

        <Stat>
        <StatNumber  mt='5px' color={txtc} fontSize='18px' >

        {DashPar1C}

        <Tooltip label={DashPar4C} aria-label='A tooltip'>


        
        <StatLabel><Text color={txtc2} as='b'>{DashPar3C}</Text></StatLabel>
    </Tooltip>



        </StatNumber>



      </Stat>
    </Flex>



      </Flex>

      
      
      </Flex>



  <Divider w='100%'/>



      <Flex  bg={cardcolor} display="flex-start" justifyContent="center">

          <SimpleGrid columns={1} spacing={0}> 

      <Flex bg='' textAlign="right"   gap='10px' mt='10px'  direction='row' alignItems='center'>  
      
          <Flex textAlign="left" direction='column'> 
          

          </Flex>

          <Progress  colorScheme={bdgc1} borderRadius='0px' height='10px' w='100%'  value={DashPar5A} />
          <Text color={txtc} as='b'> {DashPar5A}%</Text>
      </Flex>

        <Flex  bg='' gap='10px' mt='10px'  direction='row' alignItems='center'>  
      
          <Flex textAlign="left" direction='column'> 
          

          </Flex>

          <Progress  colorScheme={bdgc3} borderRadius='0px' height='10px' w='100%'  value={DashPar6A} />
          <Text color={txtc} as='b'> {DashPar6A}%</Text>
      </Flex>

          </SimpleGrid>



        <Flex mt='0px' gap='0' direction='row'>
        
        <Flex width='100%' height='200px' bg=''> 
        {chartComponent}
        </Flex>

     
    </Flex>
      
      
                <Flex 
          alignItems='center'
          justifyContent="center"
          direction='column'>
          
            <Image src={LogoIMG} boxSize="70px" objectFit="contain" />

 

          </Flex>
      
      
      
      
      
      </Flex>





    </Flex>



  </Flex>



 
</Card>
  );
}
