
import Card from "components/card/Card";
import { Box, Spacer, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import ABcomponent from "views/admin/overons/components/ABcomponent";
import ABcomponent2 from "views/admin/overons/components/ABcomponent2";
import ABcomponent4 from "views/admin/overons/components/ABcomponent4";






export default function Settings() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
<Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
  <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='10px' mb='10px'>


    <Card   
      borderRadius='5px'>
      <ABcomponent2/>        
    </Card>



  </SimpleGrid> 
</Box>
  );
}
