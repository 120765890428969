import {
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/aerilogo1.png";
import React from "react";

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";

  return (
    <Flex
      justify='center'
      direction='column'
      align='right'

      borderRadius='50px'
      position='relative'>
      <Flex
        border='0px solid'

        align='center'
        justify='center'
        mx='auto'

        position='absolute'
        left='50%'
        top='-87px'
        transform='translate(-50%, 0%)'>
        
      </Flex>

      <div> 
      <Image src={logoWhite}  boxSize="150px" objectFit="contain"/>
     
      </div>

    </Flex>
  );
}
