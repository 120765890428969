import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, } from '@chakra-ui/react'
import { MdLineAxis,  MdCheckCircleOutline } from "react-icons/md";
import LogoIMG from "assets/img/layout/aerilogo3.png";



import { 
  Flex,
  Icon, 
  Box, 
  Text, 
  Input, 
  Button,
  Stack,
  InputRightElement,
  Image,
  Center,
Square,



} from "@chakra-ui/react";

import { EmailIcon , CheckIcon,  } from '@chakra-ui/icons'


// const response = await axios.post("http://localhost:8080/saveAnswers", answers);
// const response = await axios.post("https://aeri-api.onrender.com/saveAnswers", answers);


// Fetch data from the server endpoint LINK: https://aeri-api.onrender.com/getCountry

const submitAnswers = async (answers) => {
  try {
    const response = await axios.post("https://aeri-api.onrender.com/saveAnswers", answers);
    console.log("Answers saved:", response.data);
    // Handle success, e.g., show a success message to the user
  } catch (error) {
    console.error("Error saving answers:", error);
    // Handle error, e.g., show an error message to the user
  }
};





export default function Banner(props) {

  const [users, setUsers] = useState([]);


  const handleSubmit = () => {
    // Call submitAnswers function when the user clicks the submit button
    submitAnswers(answers);
    // For demonstration purposes, set answersSubmitted to true
    setAnswersSubmitted(true);




  };

  useEffect(() => {
    // Fetch data from the http://localhost/8080/getCountry'
  
  // Fetch data from the server endpoint LINK: https://aeri-api.onrender.com/getCountry'
  axios.get('http://localhost/8080/getCountry')
  .then(response => {
    // Update the state with the fetched data
    setUsers(response.data);
  })
  .catch(error => {
    console.error('Error fetching users:', error);
  });
  }, []); // Empty dependency array to run only once when the component mounts
  
  


  const [answers, setAnswers] = useState({}); // State to store answers
  const [answersSubmitted, setAnswersSubmitted] = useState(false); // State to track if answers are submitted

  // Function to handle input change
  const handleInputChange = (question, value) => {
    setAnswers({ ...answers, [question]: value });
  };


  return (
    <Flex
      mt="10px"
      pe="0px"
      bg="#090a1c"
      width="100%"
      maxHeight=""
      maxWidth="950px"
      borderRadius="15px"
      direction="column"
      p={0} // Added padding to the Flex container
    >






<Flex 
 mt="10px"
 px="10px"
 h=""
color=''>
<Square 
borderRadius="15px"
bg='blue.800' 
size='280px'>
  <Text 
    width="100%"
    mt='0px'
    mb='10px'
    ml='20px'
    mr='20px'
    textAlign='center'
    fontWeight='800'
    fontSize='18px'
    color='gray.200'
  >

  <Flex
            alignItems='center'
          justifyContent="center"
          direction='column'>


    
Begin vandaag nog met <i><u>vergelijken</u></i> en <i><b>profiteer</b></i> van de voordelen!" 

             
</Flex>
             
             </Text>
  </Square>




  <Square  
  size='280px'
 style={{
    backgroundImage: `url('https://i.pinimg.com/564x/06/d2/8a/06d28a0fadcc092c73c5e1ddf7d33f30.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    
    borderRadius: '15px',
    // Adjusting background position
    backgroundPosition: 'center 50%', // Moves the background slightly downward

    // Lowering opacity
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Lower opacity (80%)
  }}
  
   flex='1' >  

  </Square>



</Flex>




<Flex
  mt='20px'
  mb='20px'
 alignItems='center'
   direction="column"
   flex='1'
> 

      {/* First question */}
     
        <Input
           width='50%'
           borderRadius='15px'
          color='teal'
          variant='solid'
          type="text"
          placeholder="Schrijf je nu in | Email"
          _placeholder={{ opacity: 1, color: 'gray.500' }}
          value={answers["question1"] || ""}
          onChange={(e) => handleInputChange("question1", e.target.value)}
        />


</Flex>




      <Flex
  mt='0px'
  mb='20px'
   width='100%'
   height='100%'
  alignItems='center'
  direction='column'
> 



      {/* Thank you message */}
      {answersSubmitted && (
        <Text
         mt={2}
         mb={3}
          color="green.100">

          <Flex> 
          Succesvol aangemeld! Check je mail voor de bevestiging 
          <Icon as={MdCheckCircleOutline} width="20px" ml="10px" height="20px" color="inherit" />
          </Flex>
        </Text>
      )}







      {/* Submit button */}
      <Button 
      variant='solid'
      size='md'
      alignSelf='right'
      borderRadius='15px'
      w='150px' 
      colorScheme="teal" 
      onClick={handleSubmit}>
       aanmelden
      </Button>




</Flex>




    </Flex>
  );
}
